import { useCallback, useEffect, useState } from "react";
import "react-resizable/css/styles.css";
import moment from "moment";
import {
  getHolidays,
  memberClassesData,
  monthAttendance,
  rescehdulableDates,
  takeAttendance,
} from "src/services/privateApi";
import { Formik } from "formik";
import MyInput from "src/components/common/FormElements/MyInput";
import { Form } from "react-bootstrap";
import useNav from "src/hooks/useNav";
import swal from "sweetalert";
import deepCopy from "src/utils/deepCopy";
import weekOfMonth from "src/components/utilis/weekOfMonth";
import _ from "lodash";
import AttendanceTable from "./AttendanceTable";

// update props
const StudenPageAttendance = ({
  studentInfo,
  readOnly,
  setReadOnly,
  children,
}) => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [rescehdulableDatesList, setRescehdulableDatesList] = useState({});
  const [holidayList, setHolidayList] = useState([]);
  const [updatedRows, setUpdatedRows] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  let { currentMonth } = useNav();

  const classesMember = useCallback(
    async (date, isAlert) => {
      try {
        const {
          data: { data, message },
          err,
        } = await memberClassesData(date, studentInfo.id);

        if (err) throw new Error(message);
        else if (data.length === 0) {
          isAlert &&
            swal("選択年月にはクラスデータがありません", "", "warning");
          throw new Error("class data not found");
        } else {
          return data[0];
        }
      } catch (err) {
        console.log(err);
      }
    },
    [studentInfo.id]
  );

  const attendanceMonth = useCallback(
    async (date, isAlert = true) => {
      setIsSearching(true);

      const classData = await classesMember(date, isAlert);

      const { regular_class_id } = classData ? classData : {};

      if (!regular_class_id) {
        setIsSearching(false);
        setAttendanceData([]);
        setUpdatedRows({});
        return;
      }

      const payload = {
        attendance_month: parseInt(moment(date).format("MM")),
        attendance_year: parseInt(moment(date).format("YYYY")),
        member_id: studentInfo.id,
        regular_class_id,
      };

      try {
        const {
          data: { data, message },
          err,
        } = await monthAttendance(payload);

        if (err) throw new Error(message);
        else if (data.length === 0 || data?.attendances.length === 0) {
          isAlert &&
            swal("選択年月にはクラスデータがありません", "", "warning");
          throw new Error("attendance data not found");
        } else {
          setAttendanceData(data.attendances);
          setUpdatedRows({});
        }
      } catch (err) {
        console.log(err);
      }

      setIsSearching(false);
    },
    [studentInfo.id, classesMember]
  );

  const holidaysGet = useCallback(async () => {
    setIsSearching(true);

    try {
      const {
        data: { data, message },
        err,
      } = await getHolidays();

      if (err) throw new Error(message);
      else if (data.length === 0) {
        throw new Error("holidays data not found");
      } else {
        setHolidayList(data);
      }
    } catch (err) {
      console.log(err);
    }

    setIsSearching(false);
  }, []);

  const datesReschedulable = useCallback(
    async date => {
      setIsSearching(true);

      const payload = {
        date: moment(date).format("YYYY-MM"),
        member_id: studentInfo.id,
      };

      try {
        const {
          data: { data, message },
          err,
        } = await rescehdulableDates(payload);

        if (err) throw new Error(message);
        else if (data.length === 0) {
          throw new Error("reschedule data not found");
        } else {
          setRescehdulableDatesList(data);
        }
      } catch (err) {
        console.log(err);
      }

      setIsSearching(false);
    },
    [studentInfo.id]
  );

  useEffect(() => {
    if (currentMonth) {
      setSelectedMonth(currentMonth);

      if (studentInfo.id) {
        attendanceMonth(currentMonth, false);
        datesReschedulable(currentMonth);
        holidaysGet();
      }
    }
  }, [
    studentInfo.id,
    currentMonth,
    attendanceMonth,
    datesReschedulable,
    holidaysGet,
  ]);

  const calculateTableData = () => {
    const initialTableData = [
      [{ isEmpty: true, attendanceBlock: [1, 1] }],
      [{ isEmpty: true, attendanceBlock: [2, 1] }],
      [{ isEmpty: true, attendanceBlock: [3, 1] }],
      [{ isEmpty: true, attendanceBlock: [4, 1] }],
    ];

    let tableData = deepCopy(attendanceData)
      .map(dayData => {
        const isHoliday = holidayList.some(
          holiday => holiday.date === dayData.attendance_date
        );
        return { ...dayData, isHoliday };
      })
      .reduce((tableData, dayData, index) => {
        const weekNumber = weekOfMonth(dayData.attendance_date);
        const updatedData = updatedRows[index] || {};
        if (tableData[weekNumber - 1]) {
          if (tableData[weekNumber - 1][0].isEmpty) {
            const { attendanceBlock } = tableData[weekNumber - 1].pop();
            tableData[weekNumber - 1].push({
              ...dayData,
              ...updatedData,
              attendanceBlock,
            });
          } else {
            const attendanceBlock = [undefined, 2];
            tableData[weekNumber - 1].push({
              ...dayData,
              ...updatedData,
              attendanceBlock,
            });
          }
        }
        return tableData;
      }, initialTableData);

    return tableData.flat();
  };

  const tableData = calculateTableData();

  const castValue = (value, property) => {
    if (property === "checked") {
      return value ? 1 : 0;
    }

    return value;
  };

  const handleUpdate = (...args) => {
    // Ensure toUpdateList is an array of updates
    const toUpdateList = Array.isArray(args[0]) ? args[0] : [args];
    const newUpdatedRows = deepCopy(updatedRows);

    toUpdateList.forEach(val => {
      const [e, index, property] = val;
      const { name, [property]: value } = e.target;
      if (!newUpdatedRows[index]) {
        newUpdatedRows[index] = { id: attendanceData[index].id };
      }
      newUpdatedRows[index][name] = castValue(value, property);
    });

    setUpdatedRows(newUpdatedRows);
  };

  const handleSave = async () => {
    if (_.isEmpty(updatedRows)) {
      setReadOnly(true);
      return;
    }

    // Indicate that the operation is ongoing.
    setIsSearching(true);

    // Prepare the payload with an empty attendances array.
    const payload = {
      attendances: [],
    };

    // Helper function to get value from updated rows or fallback to attendance data.
    const getValue = (id, property) => {
      return property in updatedRows[id]
        ? updatedRows[id][property]
        : attendanceData[id][property];
    };

    // Populate the payload with updated attendance data.
    Object.keys(updatedRows).forEach(id => {
      payload.attendances.push({
        id: getValue(id, "id"),
        present: getValue(id, "present"),
        comment: getValue(id, "comment"),
        rescheduled: getValue(id, "rescheduled"),
        reschedule_regular_class_schedule: getValue(
          id,
          "reschedule_regular_class_schedule"
        ),
        reschedule_date: getValue(id, "reschedule_date"),
        reschedule_present: getValue(id, "reschedule_present"),
      });
    });

    // Validate the payload for required fields if rescheduled.
    const isValidated = payload.attendances.every(attendance => {
      if (attendance.rescheduled === 1) {
        return (
          attendance.reschedule_regular_class_schedule !== undefined &&
          attendance.reschedule_date
        );
      }
      return true;
    });

    if (!isValidated) {
      swal("振替日も入力してください", "", "warning");
      setIsSearching(false);
      return;
    }

    // Attempt to save the attendance data.
    try {
      const { err, data } = await takeAttendance(payload);

      if (err) {
        throw new Error(
          data.message || "An error occurred while saving attendance."
        );
      }

      console.log("Attendance saved successfully:", data);
      datesReschedulable(selectedMonth);
      setReadOnly(true);
    } catch (error) {
      console.error("Failed to save attendance:", error);
      swal("出欠データを保存できませんでしたできませんでした", "", "error");
    } finally {
      // Ensure the loading state is reset regardless of success or failure.
      setIsSearching(false);
    }
  };

  return (
    <div class="container-fluid">
      {children}
      <div className="row m-auto ">
        <div className="card">
          <div className="card-body">
            <div className="row  my-2">
              <Formik
                initialValues={{ date: selectedMonth }}
                enableReinitialize={true}
                onSubmit={({ date }) => {
                  setSelectedMonth(date);
                  attendanceMonth(date);
                  datesReschedulable(date);
                }}
              >
                {formik => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="col-md-5 row">
                      <div className="col-3 text-md-end pt-1">年月:</div>
                      <div className="col-6">
                        <MyInput
                          label=""
                          name="date"
                          type="month"
                          placeholder=""
                        />
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-primary w-100"
                          type="submit"
                          disabled={isSearching}
                        >
                          検索
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="col-md-5"></div>
            </div>
            <AttendanceTable
              tableData={tableData}
              handleUpdate={handleUpdate}
              readOnly={readOnly}
              rescehdulableDatesList={rescehdulableDatesList}
            />
            <div className="row justify-content-end d-flex">
              <div className="col-1 mt-2 p-0 d-flex justify-content-end">
                <button
                  className="btn btn-primary w-75"
                  onClick={handleSave}
                  disabled={readOnly || isSearching}
                >
                  保存
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudenPageAttendance;
