import React from "react";
import { Select } from "antd";
const RescheduledDayInput = ({ options, value, disabled, onChange }) => {
  return (
    <div style={{ paddingTop: "2px", paddingBottom: "2px" }}>
      <Select
        value={value || "日にちを選んでください"}
        disabled={disabled}
        onChange={onChange}
        style={{ width: "100%" }}
      >
        {options.map(option => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
export default RescheduledDayInput;