import generateClassFormData from "./generateClassFormData";
import { v4 as uuidv4 } from "uuid";

const generateClassFormListInfo = (classList, selectedClassId, date = null) => {
  const id = uuidv4() + "_generated_data";
  let newFormList = [{ id: id }];
  let newSelectedClassId = id;
  let newBillingAmount = 0;
  let newDate = date;

  if (classList) {
    newFormList = classList.map(val => generateClassFormData(val));
    newSelectedClassId = selectedClassId || classList[0].id;
    newDate = classList[0].processed_date;
    newBillingAmount = classList[0].billing_amount;
  }

  return { newFormList, newSelectedClassId, newBillingAmount, newDate };
};

export default generateClassFormListInfo;
