import DateForm from "src/components/common/DateForm";
import { Checkbox } from "antd";
import TableCellOverflowDiv from "src/components/common/Button/TableCellOverflowDiv";
import numberWithComma from "src/utils/numberWithComma";
import Nvh from "src/components/common/Nvh";

// note praveen, can refactor, wont do it now
const studentClassTableColumns = () => {
  const dynamicColumns = [...Array(5)].flatMap((_, i) => [
    {
      title: `曜日${i + 1}`,
      dataIndex: `day_week_${i + 1}`,
      key: `day_week_${i + 1}`,
      width: 150,
      sorter: (a, b) => {
        const aValue = (a[`day_week_${i + 1}`] || "").toString();
        const bValue = (b[`day_week_${i + 1}`] || "").toString();
        return aValue.localeCompare(bValue);
      },
    },
    {
      title: `時間${i + 1}`,
      dataIndex: `start_time_${i + 1}`,
      key: `start_time_${i + 1}`,
      width: 150,
      sorter: (a, b) => {
        const aValue = (a[`start_time_${i + 1}`] || "").toString();
        const bValue = (b[`start_time_${i + 1}`] || "").toString();
        return aValue.localeCompare(bValue);
      },
    },
    {
      title: `バス${i + 1}`,
      dataIndex: `bus_flag_${i + 1}`,
      key: `bus_flag_${i + 1}`,
      width: 150,
      render: text => <Checkbox checked={text === 1} readOnly />,
      sorter: (a, b) => {
        const aValue = Number(a[`bus_flag_${i + 1}`]);
        const bValue = Number(b[`bus_flag_${i + 1}`]);
        return aValue - bValue;
      },
    },
  ]);

  const columns = [
    {
      title: "処理年月",
      width: 150,
      dataIndex: "processed_date",
      key: "processed_date",
      render: (text, record) => DateForm({ value: text, type: "short" }),
      sorter: (a, b) => new Date(a.processed_date) - new Date(b.processed_date),
    },
    {
      title: "回数",
      width: 150,
      dataIndex: "times",
      key: "times",
      sorter: (a, b) => a.times - b.times,
    },
    {
      title: "所属",
      dataIndex: "affiliation_name",
      key: "affiliation_name",
      width: 150,
      render: (text, record) => (
        <span>{Nvh({ value: record?.regular_class?.affiliation_name })}</span>
      ),
      sorter: (a, b) =>
        a.regular_class?.affiliation_name.localeCompare(
          b.regular_class?.affiliation_name
        ),
    },
    {
      title: "級",
      width: 150,
      dataIndex: "class",
      key: "class",
      sorter: (a, b) => {
        const classA = a.class || "";
        const classB = b.class || "";
        return classA.localeCompare(classB);
      },
    },
    {
      title: "休校",
      width: 150,
      dataIndex: "closed_flag",
      key: "closed_flag",
      render: text => <Checkbox checked={text === 1} readOnly />,
      sorter: (a, b) => a.closed_flag - b.closed_flag,
    },
    // Include the dynamic columns
    ...dynamicColumns,
  ];

  return columns;
};

export default studentClassTableColumns;
