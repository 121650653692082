import moment from "moment";
import ActualValue from "../../common/ActualValue";
import React from "react";
import createArrayFromSize from "../../../utils/creatArrayFromSize";
import sumArray from "../../../utils/sumArray";

const BlankRows = ({ telView, idView, numberOfBlankRows }) => {
  const numberOfDummyRowColumns =
    telView && idView ? 8 : telView || idView ? 7 : 6;

  const dummyRow = createArrayFromSize(numberOfDummyRowColumns).map(val => (
    <td key={`extraRow-${val}`}>&nbsp;</td>
  ));

  return createArrayFromSize(numberOfBlankRows).map(val => (
    <tr key={`extraRow-${val}`}>{dummyRow}</tr>
  ));
};

const RidingDataTable = ({
  ridingData,
  tableRef,
  idView,
  telView,
  ridingReports,
  contentRef,
}) => {
  const rowsGroupedByStation = ridingReports
    ? ridingReports.reduce((acc, curr, index) => {
        const riderLength = curr.riders.length;
        if (riderLength) {
          acc[index + 1] =
            1 + (riderLength === 1 ? riderLength + 1 : riderLength);
          // 1 +    .... accounting the tally row
          // rider + 1    .... accounting the merged cells on the bus station name column
        }
        return acc;
      }, {})
    : {};

  const totalRows = Object.values(rowsGroupedByStation).reduce(
    (acc, curr) => (acc += curr),
    0
  );

  let numberOfBlankRows = 0;

  let pages = [19];

  const currentTotalPages = () => sumArray(pages);

  if (totalRows <= currentTotalPages() - 2) {
    numberOfBlankRows = currentTotalPages() - 2 - totalRows;
  } else {
    do {
      pages[pages.length - 1] += 1;
      pages[pages.length] = 21;

      // when a cell consist of 2 merged rows and its the last row on the page, browser rolls it over to the next page, the following logic handles this edge case
      let incrementalRowSum = 0;
      const nMinusPagesSum = sumArray(pages.slice(0, -1));

      const stationsList = Object.keys(rowsGroupedByStation);
      const totalStations = stationsList.length;

      // it might be hard going to understand whats going on here
      // let me clarify with an example
      // suppose there are only 2 pages now
      // the first page would then have a total of 20 rows
      // say the first bus station has a total of 18 rows
      // this would then cause the next record to overflow according to browser behaviour, so we have to adjust the number of total rows the first page contains
      // by subracting a row from it, thus adjustig for the auto rolling that the browser excutes on printed tables
      // we dont subract anything if there are no extra rows than which can fit on the page
      // the loop checks if the row sum is equal to exactly 1 less than the total rows from the first page till the second last page (comparing the last page doesnt make any sense)

      for (let i = 0; i < totalStations; i++) {
        incrementalRowSum += rowsGroupedByStation[stationsList[i]];

        if (
          incrementalRowSum === nMinusPagesSum - 1 &&
          i !== totalStations - 1
        ) {
          pages[pages.length - 2] -= 1;
          break;
        }
      }

      numberOfBlankRows = currentTotalPages() - 2 - totalRows;
    } while (numberOfBlankRows < 0);
  }

  return (
    <div ref={contentRef} className="table-responsive" id="ridingTable">
      <div className="p-3 mb-3 light-border">
        <div className="bg-light p-2">
          <div className="row ride_headline">
            <h5 className="col-4">
              {ridingData?.class_name} - {ridingData?.time}
            </h5>
            <h5 className="col-4 text-center">{ridingData?.day_week} 曜日</h5>
            <h5 className="col-4 text-end">{ridingData?.bus_name} コース</h5>
          </div>
        </div>

        <div className="table-responsive">
          <table
            className="mb-0 table table-bordered mt-2 custom-tbl2 align-middle"
            ref={tableRef}
          >
            <thead style={{ marginTop: "10px" }}>
              <tr>
                <th>バス停</th>
                {idView && <th>No</th>}
                <th>名前</th>
                <th>フリガナ</th>
                <th>往</th>
                <th>復</th>
                {telView && <th>TEL</th>}
                <th className="truncated-text">備考</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "15px" }}>
              {ridingReports &&
                ridingReports.map((stopage, stopageIndex) => (
                  <React.Fragment key={stopageIndex}>
                    {stopage.riders.length > 0 && (
                      <>
                        {stopage.riders.map((rider, riderIndex) => (
                          <React.Fragment key={`${stopageIndex}-${riderIndex}`}>
                            <tr>
                              {riderIndex === 0 && (
                                <td
                                  style={{ verticalAlign: "baseline" }}
                                  rowSpan={
                                    stopage.riders.length === 1
                                      ? 2
                                      : stopage.riders.length
                                  }
                                >
                                  <strong style={{ fontSize: "20px" }}>
                                    {stopage.stopage_name}
                                  </strong>
                                  <br />
                                  {stopage.time}
                                </td>
                              )}

                              {idView && <td>{ActualValue(rider.no)}</td>}

                              <td>{rider.name}</td>
                              <td>{rider.name_furigana}</td>
                              <td className="text-center">
                                {rider.start ? "⭕️" : "❌"}
                              </td>
                              <td className="text-center">
                                {rider.return ? "⭕️" : "❌"}
                              </td>

                              {telView && <td>{rider.tel}</td>}

                              <td className="truncated-text">
                                {rider.note?.length > 8
                                  ? rider.note?.substring(0, 15) + "..."
                                  : rider.note}{" "}
                              </td>
                            </tr>
                            {stopage.riders.length === 1 && (
                              <tr className="riding-list-hacky-fix">
                                {idView && <td>{ActualValue(rider.no)}</td>}

                                <td>{rider.name}</td>
                                <td>{rider.name_furigana}</td>
                                <td className="text-center">
                                  {rider.start ? "⭕️" : "❌"}
                                </td>
                                <td className="text-center">
                                  {rider.return ? "⭕️" : "❌"}
                                </td>

                                {telView && <td>{rider.tel}</td>}

                                <td className="truncated-text">
                                  {rider.note?.length > 8
                                    ? rider.note?.substring(0, 15) + "..."
                                    : rider.note}{" "}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                        <tr>
                          <td>&nbsp;</td>
                          {idView && <td>&nbsp;</td>}
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td className="text-center">
                            <strong>
                              {stopage.riders.reduce(
                                (total, rider) => total + (rider.start ? 1 : 0),
                                0
                              )}
                            </strong>
                          </td>
                          <td className="text-center">
                            <strong>
                              {stopage.riders.reduce(
                                (total, rider) =>
                                  total + (rider.return ? 1 : 0),
                                0
                              )}
                            </strong>
                          </td>
                          {telView && <td>&nbsp;</td>}
                          <td>&nbsp;</td>
                        </tr>
                      </>
                    )}
                  </React.Fragment>
                ))}
              <BlankRows {...{ telView, idView, numberOfBlankRows }} />
              <tr>
                <td colSpan={idView ? 4 : 3}></td>
                <td className="text-center">
                  <strong>往</strong>
                </td>
                <td className="text-center">
                  <strong>復</strong>
                </td>
                <td colSpan={telView ? 2 : 1}></td>
              </tr>
              <tr>
                <td colSpan={idView ? 4 : 3}></td>
                <td className="text-center">
                  <strong>
                    {ridingReports?.reduce(
                      (total, stopage) =>
                        total +
                        stopage.riders.reduce(
                          (riderTotal, rider) =>
                            riderTotal + (rider.start ? 1 : 0),
                          0
                        ),
                      0
                    )}
                  </strong>
                </td>
                <td className="text-center">
                  <strong>
                    {ridingReports?.reduce(
                      (total, stopage) =>
                        total +
                        stopage.riders.reduce(
                          (riderTotal, rider) =>
                            riderTotal + (rider.return ? 1 : 0),
                          0
                        ),
                      0
                    )}
                  </strong>
                </td>
                <td colSpan={telView ? 2 : 1}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="mx-2">
        印刷日: {moment(new Date()).format("YYYY-MM-DD")}
      </div>
    </div>
  );
};

export default RidingDataTable;
