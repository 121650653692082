import DateForm from "src/components/common/DateForm";
import { Checkbox } from "antd";
import TableCellOverflowDiv from "src/components/common/Button/TableCellOverflowDiv";
import numberWithComma from "src/utils/numberWithComma";

const billingTableColums = () => {
  const columns = [
    {
      title: "処理年月",
      dataIndex: "processed_date",
      key: "processed_date",
      width: 100,
      render: processed_date =>
        DateForm({ value: processed_date, type: "short" }),
      sorter: (a, b) => new Date(a.processed_date) - new Date(b.processed_date),
    },
    {
      title: "休校",
      dataIndex: "closed_class",
      key: "closed_class",
      width: 70,
      render: closed_class => <Checkbox checked={closed_class} disabled />,
      sorter: (a, b) => a.closed_class - b.closed_class,
    },
    {
      title: "請求額",
      dataIndex: "billing_amount",
      key: "billing_amount",
      width: 100,
      render: value => (
        <div className="text-end" disabled>
          {numberWithComma(value)}
        </div>
      ),
      sorter: (a, b) => a.billing_amount - b.billing_amount,
    },
    {
      title: "振替請求額",
      dataIndex: "transfer_invoice_amount",
      key: "transfer_invoice_amount",
      width: 100,
      render: value => (
        <div className="text-end" disabled>
          {numberWithComma(value)}
        </div>
      ),
      sorter: (a, b) => a.transfer_invoice_amount - b.transfer_invoice_amount,
    },
    {
      title: "振替入金額",
      dataIndex: "transfer_amount",
      key: "transfer_amount",
      width: 100,
      render: value => (
        <div className="text-end" disabled>
          {numberWithComma(value)}
        </div>
      ),
      sorter: (a, b) => a.transfer_amount - b.transfer_amount,
    },
    {
      title: "振替入金日",
      dataIndex: "transfer_deposit_date",
      key: "transfer_deposit_date",
      width: 100,
      render: transfer_deposit_date =>
        DateForm({ value: transfer_deposit_date }),
      sorter: (a, b) =>
        new Date(a.transfer_deposit_date) - new Date(b.transfer_deposit_date),
    },
    {
      title: "現金入金額",
      dataIndex: "cash_deposit_amount",
      key: "cash_deposit_amount",
      width: 100,
      render: value => (
        <div className="text-end" disabled>
          {numberWithComma(value)}
        </div>
      ),
      sorter: (a, b) => a.cash_deposit_amount - b.cash_deposit_amount,
    },
    {
      title: "現金入金日",
      dataIndex: "cash_deposit_date",
      key: "cash_deposit_date",
      width: 100,
      render: cash_deposit_date => DateForm({ value: cash_deposit_date }),
      sorter: (a, b) =>
        new Date(a.cash_deposit_date) - new Date(b.cash_deposit_date),
    },
    {
      title: "現金入金額-2",
      dataIndex: "cash_deposit_amount2",
      key: "cash_deposit_amount2",
      width: 100,
      render: value => (
        <div className="text-end" disabled>
          {numberWithComma(value)}
        </div>
      ),
      sorter: (a, b) => a.cash_deposit_amount2 - b.cash_deposit_amount2,
    },
    {
      title: "現金入金日-2",
      dataIndex: "cash_deposit_date2",
      key: "cash_deposit_date2",
      width: 100,
      render: cash_deposit_date2 => DateForm({ value: cash_deposit_date2 }),
      sorter: (a, b) =>
        new Date(a.cash_deposit_date2) - new Date(b.cash_deposit_date2),
    },
    {
      title: "入金額合計",
      dataIndex: "total_deposit_amount",
      key: "total_deposit_amount",
      width: 100,
      render: value => (
        <div className="text-end" disabled>
          {numberWithComma(value)}
        </div>
      ),
      sorter: (a, b) => a.total_deposit_amount - b.total_deposit_amount,
    },
    {
      title: "過不足額",
      dataIndex: "excess_deficiency",
      key: "excess_deficiency",
      width: 100,
      render: value => (
        <div className="text-end" disabled>
          {numberWithComma(value)}
        </div>
      ),
      sorter: (a, b) => a.excess_deficiency - b.excess_deficiency,
    },
    {
      title: "過不足累計",
      dataIndex: "cumulative_excess_deficiency",
      key: "cumulative_excess_deficiency",
      width: 100,
      render: value => (
        <div className="text-end" disabled>
          {numberWithComma(value)}
        </div>
      ),
      sorter: (a, b) =>
        a.cumulative_excess_deficiency - b.cumulative_excess_deficiency,
    },
    {
      title: "備考",
      dataIndex: "note",
      width: 100,
      key: "note",
      render: note => (
        <TableCellOverflowDiv title={note} width="100px">
          {note}
        </TableCellOverflowDiv>
      ),
    },
    {
      title: "チェック",
      dataIndex: "billing_check",
      key: "billing_check",
      width: 80,
      render: billing_check => (
        <Checkbox checked={billing_check === 1} disabled />
      ),
      sorter: (a, b) => a.billing_check - b.billing_check,
    },
  ];

  return columns;
};

export default billingTableColums;
