import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import RankPageFilter from "src/components/admin/rankManagement/RankPageFilter";
import useNav from "src/hooks/useNav";
import { dateFormat } from "src/utils/formatdate";
import moment from "moment";
import messages from "src/utils/messages";
import { swalError, swalSuccess, swalWarning } from "src/utils/swalHelpers";
import {
  memberClassesDataNew,
  members as _members,
  memberClassAssign,
} from "src/services/privateApi";
import ClassDetails from "src/components/admin/rankManagement/ClassDetails";
import StudentDetails from "src/components/admin/rankManagement/StudentDetails";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const RankHistory = () => {
  const [monthsInfo, setMonthsInfo] = useState(null);
  const [memberData, setMemberData] = useState({});
  const [classData, setClassData] = useState([]);
  const [updatedClass, setUpdatedClass] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const searchInputRef = useRef(null);
  const { currentMonth } = useNav();

  useEffect(() => {
    if (currentMonth) {
      const monthsInfo = ["前月", "当月", "翌月"].map((label, index) => {
        const addAmount = index - 1;
        let date = moment(currentMonth).add(addAmount, "months");
        date = dateFormat(date, 3);
        return { label, date, isSelected: label === "当月" };
      });

      setMonthsInfo(monthsInfo);
    }
  }, [currentMonth]);

  const focusInput = () => {
    setTimeout(() => {
      searchInputRef.current.focus();
    });
  };

  const resetState = () => {
    setMemberData({});
    setClassData([]);
    setUpdatedClass({});
  };

  // note praveen similar function is used in student class table
  const fetchMemberClassesData = async memberId => {
    const [start_date, end_date] = [monthsInfo[0].date, monthsInfo[2].date];
    const payload = { start_date, end_date };
    const { resType, data } = await memberClassesDataNew(payload, memberId);

    if (resType === 1) setClassData(data.data);
    else {
      if (resType === 2) await swalError(messages.classDataNotFound);
      if (resType === 3) await swalError(data.message); // messages.contactMessage
      focusInput();
    }
  };

  const fetchMemberData = async memberCode => {
    resetState();
    if (memberCode) {
      const payload = { member_code: memberCode };
      const { resType, data } = await _members(payload);

      if (resType === 1) {
        const memberData = data.data[0];
        setMemberData(memberData);
        await fetchMemberClassesData(memberData.id);
      } else {
        if (resType === 2) await swalError(messages.memberNotFound);
        if (resType === 3) await swalError(data.message);
        focusInput();
      }
    } else {
      await swalWarning(messages.enterMemberCode);
      focusInput();
    }
  };

  const handleSave = async () => {
    if (_.isEmpty(updatedClass)) {
      await swalSuccess(messages.classDataUpdate);
      focusInput();
      return;
    }

    setIsSubmitting(true);

    const payload = {
      weekdays: [],
      class_times: [],
      buses: [],
      member_id: memberData.id,
      month: updatedClass.processed_date,
      class_id: updatedClass.regular_class_id,
      times: updatedClass.times,
      class_level: updatedClass.class,
      billing_amount: updatedClass.billing_amount,
      closed_flag: updatedClass.closed_flag,
      id: updatedClass.id,
    };

    [1, 2, 3, 4, 5].forEach(val => {
      payload.weekdays.push(updatedClass[`day_week_${val}`] || "");
      payload.buses.push(updatedClass[`bus_flag_${val}`]);
      payload.class_times.push({
        start_time: updatedClass[`start_time_${val}`] || "",
        end_time: updatedClass[`end_time_${val}`] || "",
        class_level: updatedClass[`class_level_${val}`] || "",
      });
    });

    const { resType, data } = await memberClassAssign(payload);

    if (resType === 1) {
      await swalSuccess(messages.classDataUpdate);
      setClassData([
        ...classData.filter(
          val => val.processed_date !== updatedClass.processed_date
        ),
        updatedClass,
      ]);
      focusInput();
    } else if (resType === 3) {
      await swalError(data?.message);
    }

    setIsSubmitting(false);
  };

  const toggleSelectedMonth = month => {
    const newMonthsInfo = monthsInfo.map(val => {
      if (val.label === month) return { ...val, isSelected: true };
      return { ...val, isSelected: false };
    });

    const classDetailInfo = mergeMonthInfoWithClassData(newMonthsInfo);
    setUpdatedClass(classDetailInfo[classDetailInfo.length - 1].class);
    setMonthsInfo(newMonthsInfo);
  };

  const mergeMonthInfoWithClassData = (newMonthsInfo = monthsInfo) => {
    if (!newMonthsInfo || !classData.length) return null;

    const classDetailInfo = newMonthsInfo
      .reduce((acc, curr) => {
        if (!acc.some(val => val.isSelected)) acc.push(curr);
        return acc;
      }, [])
      .map((month, index, arr) => {
        let monthClass = classData.find(
          data => data.processed_date === month.date
        );

        if (!monthClass) {
          monthClass = classData.find(data => {
            const prevMonth = arr[index - 1];
            return data?.processed_date === prevMonth?.date;
          });
          monthClass = monthClass || {};
          monthClass.id = uuidv4();
        }

        return {
          ...month,
          class: { ...monthClass, processed_date: month.date },
        };
      });

    return classDetailInfo;
  };

  const classDetailInfo = mergeMonthInfoWithClassData();

  return (
    <div className="card rank-history">
      <div className="card-header">
        <button className="btn px-3 mr-4 btn-primary">進級処理</button>
      </div>
      <div className="card-body">
        <RankPageFilter
          toggleSelectedMonth={toggleSelectedMonth}
          selectedMonth={monthsInfo?.find(val => val.isSelected)?.label}
          searchInputRef={searchInputRef}
          fetchMemberData={fetchMemberData}
        />
        <StudentDetails memberData={memberData} />
        {classDetailInfo && (
          <>
            {classDetailInfo.map(month => {
              return (
                <ClassDetails
                  key={month.label}
                  monthLabel={month.label}
                  isSelected={month.isSelected}
                  date={month.date}
                  classInfo={month.class}
                  updatedClass={updatedClass}
                  setUpdatedClass={setUpdatedClass}
                />
              );
            })}
            <Button
              className="float-right col-md-1 mt-2"
              variant="primary"
              onClick={handleSave}
              style={{ float: "right" }}
              disabled={isSubmitting}
            >
              保存
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default RankHistory;
