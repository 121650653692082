import { useField } from "formik";
import { Form } from "react-bootstrap";

// date, text, number
function MyInput({ label, selectTextOnFocus, inputRef, ...props }) {
  const [field, meta] = useField(props);

  const isInvalid = !!meta.touched && !!meta.error;

  const handleFocus = e => {
    if (selectTextOnFocus) {
      e.target.select();
    }
  };

  const allProps = { ...props, ...field, isInvalid, onFocus: handleFocus };

  const labelClass = !!label ? "" : "d-none";
  const feedbackClass =
    meta.error === "no message" ? "feeback m-0 px-1 d-none" : "feeback px-1";

  return (
    <Form.Group className="my-input" controlId={props.name}>
      <Form.Label className={labelClass}>{label}</Form.Label>
      <Form.Control ref={inputRef} {...allProps} />
      <Form.Control.Feedback className={feedbackClass} type="invalid">
        {meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

export default MyInput;
