import createArrayFromSize from "./creatArrayFromSize";

const generateClassFormData = classInfo => {
  const formData = {};

  const formFields = [
    "regular_class_id",
    "times",
    "class",
    "closed_flag",
    "billing_amount",
    "processed_date",
    createArrayFromSize(5).map(val => {
      return [
        `day_week_${val}`,
        `start_time_${val}`,
        `end_time_${val}`,
        `class_level_${val}`,
        `bus_flag_${val}`,
      ];
    }),
  ].flat(Infinity);

  if (classInfo) {
    formData.id = classInfo.id;
    formFields.forEach(field => {
      formData[field] = classInfo[field];
    });

    formData.specialStudy = formData.class === "0" ? 1 : 0;
  } else {
    formFields.forEach(field => {
      formData[field] = null;
    });
    formData.class = "";
    formData.billing_amount = 0;
    formData.specialStudy = 0;
    formData.closed_flag = 0;
    createArrayFromSize(5).forEach(val => {
      formData[`bus_flag_${val}`] = 0;
    });
  }

  return formData;
};

export default generateClassFormData;
