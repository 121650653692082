import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "../../assets/coach/css/styles.css";
import "../../assets/coach/js/scripts";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import routes from "../../routes/studentRoutes";
import NavProvider from "src/provider/navProvider";

const MasterLayout = () => {
  return (
    <NavProvider>
      <div className="sb-nav-fixed">
        <Navbar />
        <div id="layoutSidenav">
          <div id="layoutSidenav_nav">
            <Sidebar />
          </div>

          <div id="layoutSidenav_content">
            <main>
              <Switch>
                {routes.map((route, idx) => {
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      />
                    )
                  );
                })}
                <Redirect from="/student" to="/student/dashboard" />
              </Switch>
            </main>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </NavProvider>
  );
};

export default MasterLayout;
