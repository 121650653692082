import axios from "axios";
import { getToken, destroyToken } from "./auth/token";
import messages from "src/utils/messages";

class PrivateApi {
  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
    axios.defaults.headers.common["Accept"] = `application/json`;
    axios.defaults.headers.common["Content-Type"] = `application/json`;
  }

  async get(slug = "") {
    try {
      const response = await axios.get(`${slug}`);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  async post(slug, params, responseType = "json") {
    try {
      const response = await axios.post(`${slug}`, params, {
        responseType: responseType,
      });

      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  async update(slug, params) {
    try {
      const response = await axios.put(`${slug}`, params);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  async formUpdate(slug, params) {
    try {
      const response = await axios.post(`${slug}`, params);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  async delete(slug) {
    try {
      const response = await axios.delete(`${slug}`);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        destroyToken();
      }

      return error.response;
    }
  }

  async callApi(options) {
    let { url, method, payload } = options;

    let response = {};

    switch (method) {
      case "get":
        if (payload) {
          const params = new URLSearchParams(payload).toString();
          url = url + "?" + params;
        }
        response = await privateApi.get(url);
        break;

      case "post":
        response = await privateApi.post(url, payload);
        break;

      case "put":
        response = await privateApi.update(url, payload);
        break;

      default:
        response = { err: null, data: {} }; // note praveen fallback
    }

    if (response.status === 200) {
      return { err: null, data: response.data };
    } else {
      return { err: true, data: response.data };
    }
  }

  async callGetApiV2(options) {
    try {
      const response = await this.callApi(options);

      let { err, data } = response;

      if (err) {
        console.error(data?.message);
        throw new Error();
      }

      if (Array.isArray(data.data) && data.data.length === 0) {
        return { resType: 2, data }; // Empty response
      }

      return { resType: 1, data }; // Successful response
    } catch (err) {
      const data = { message: messages.contactMessage };
      return {
        resType: 3,
        data,
      }; // error
    }
  }

  async callPostApiV2(options) {
    try {
      const response = await this.callApi(options);

      let { err, data } = response;

      if (err) {
        console.error(data?.message);
        throw new Error();
      }

      // note praveen this code still needs to handle other errors
      if (data.status) {
        return { resType: 1, data };
      }
    } catch (err) {
      const data = { message: messages.contactMessage };
      return {
        resType: 3,
        data,
      }; // error
    }
  }

  redirectToUrlWithPost = (url, postData) => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = url;
    form.target = "_blank";

    for (const key in postData) {
      if (postData.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = postData[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  handleClickRedirect = (url, data) => {
    const fullurl = process.env.REACT_APP_BASE_URL + url;

    this.redirectToUrlWithPost(fullurl, data);
  };
}

const privateApi = new PrivateApi();

// note praveen
// create a new better api on the backend
export async function searchDeposit(payload) {
  const options = {
    url: "admin/search-deposite",
    method: "post",
    payload,
  };

  return privateApi.callApi(options);
}

export async function createDeposit(payload) {
  const options = {
    url: "admin/deposit",
    method: "post",
    payload,
  };

  return privateApi.callApi(options);
}

export function updateDeposit(payload, id) {
  const options = {
    url: `admin/deposit/${id}`,
    method: "put",
    payload,
  };

  return privateApi.callApi(options);
}

// note praveen
// could also alias it as depositList
// the url is ugly, try to split it into its own seperate code
export function searchMemberDeposit(payload, studentId) {
  const options = {
    url: `admin/member-deposite/${studentId}`,
    method: "get",
    payload,
  };

  return privateApi.callApi(options);
}

export function memberClasses(payload) {
  const options = {
    url: "admin/get-member-classes",
    method: "post",
    payload,
  };

  return privateApi.callApi(options);
}

// note theres scope for refactoring on both the frontend and the backend
export function memberClassesData(date, studentId) {
  const payload = { start_date: date, end_date: date };

  const options = {
    url: `admin/get-member-classes-data/${studentId}`,
    method: "get",
    payload,
  };

  return privateApi.callApi(options);
}

// note theres scope for refactoring on both the frontend and the backend
export function monthAttendance(payload) {
  const options = {
    url: `/admin/monthAttendance`,
    method: "post",
    payload,
  };

  return privateApi.callApi(options);
}

export function rescehdulableDates(payload) {
  const options = {
    url: `/admin/reschedulable-dates`,
    method: "post",
    payload,
  };

  return privateApi.callApi(options);
}

export function getHolidays() {
  const options = {
    url: `/admin/holidays`,
    method: "get",
  };

  return privateApi.callApi(options);
}

export function takeAttendance(payload) {
  const options = {
    url: "admin/takeAttendance",
    method: "post",
    payload,
  };

  return privateApi.callApi(options);
}

// note praveen, starting this function we will be handling the errors here
// change the others later
// start using callApiV2, repeated code but meh
export async function members(payload) {
  const options = {
    url: "admin/members",
    method: "get",
    payload,
  };

  const response = await privateApi.callGetApiV2(options);

  return response;
}

export async function memberClassesDataNew(payload, memberId) {
  const options = {
    url: `admin/get-member-classes-data/${memberId}`,
    method: "get",
    payload,
  };

  const response = await privateApi.callGetApiV2(options);

  return response;
}

// note praveen add validation on the backend later
export async function memberClassAssign(payload) {
  const options = {
    url: `admin/member-class-assign`,
    method: "post",
    payload,
  };

  const response = await privateApi.callPostApiV2(options);

  return response;
}

export async function regularClasses(payload) {
  const options = {
    url: `admin/classes`,
    method: "get",
    payload,
  };

  const response = await privateApi.callGetApiV2(options);

  return response;
}

// note praveen, payload lol, cant help, the api is bad
export async function tutionFees(payload) {
  const options = {
    url: `admin/tuition-fees?page=1&per_page=1000`,
    method: "get",
    payload,
  };

  const response = await privateApi.callGetApiV2(options);

  return response;
}

export async function classSchedule(payload, regularClassId) {
  const options = {
    url: `admin/class-schedules/${regularClassId}`,
    method: "get",
    payload,
  };

  const response = await privateApi.callGetApiV2(options);

  return response;
}

export async function classScheduleAll(payload) {
  const options = {
    url: `admin/all-class-schedules`,
    method: "get",
    payload,
  };

  const response = await privateApi.callGetApiV2(options);

  return response;
}

export async function classLevels(payload) {
  const options = {
    url: `admin/class-levels`,
    method: "get",
    payload,
  };

  const response = await privateApi.callGetApiV2(options);

  return response;
}

export async function memberClassDelete(payload) {
  const options = {
    url: `admin/member-class-delete`,
    method: "post",
    payload,
  };

  const response = await privateApi.callPostApiV2(options);

  return response;
}

// note praveen, this changes once again lol
export const fetchMemberClassesData = async (
  memberId,
  start_date,
  end_date,
  cb
) => {
  const payload = { start_date, end_date };
  const { resType, data } = await memberClassesDataNew(payload, memberId);
  await cb(resType, data);
};

export default privateApi;
