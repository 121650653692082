import { Form } from "react-bootstrap";

// date, text, number
function MyBsInput({ label, selectTextOnFocus, inputRef, ...props }) {
  const handleFocus = e => {
    if (selectTextOnFocus) {
      e.target.select();
    }
  };

  const allProps = { ...props, onFocus: handleFocus };

  const labelClass = !!label ? "" : "d-none";

  return (
    <Form.Group className="my-input" controlId={props.name}>
      <Form.Label className={labelClass}>{label}</Form.Label>
      <Form.Control ref={inputRef} {...allProps} />
      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );
}

export default MyBsInput;
