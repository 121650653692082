import moment from "moment";
import Nvh from "src/components/common/Nvh";
import swal from "sweetalert";

// not praveen, while not high priority theres scope to refactor this component
const BillingTableCSVDownload = ({ studentInfo, depositList }) => {
  async function download_table_as_csv(
    table_id,
    classDataCSVExport,
    separator = ","
  ) {
    var csv = [];
    var header = [];
    header.push('"会員番号"');
    header.push('"氏名"');
    header.push('"処理年月"');
    header.push('"休校"');
    header.push('"請求額"');
    header.push('"振替請求額"');
    header.push('"振替入金額"');
    header.push('"振替入金日"');
    header.push('"現金入金額"');
    header.push('"現金入金日"');
    header.push('"現金入金額(2)"');
    header.push('"現金入金日(2)"');
    header.push('"入金額合計"');
    header.push('"過不足額累計"');
    header.push('"備考"');
    header.push('"チェック"');
    csv.push(header.join(separator));
    classDataCSVExport &&
      classDataCSVExport.forEach(data => {
        var row = [];
        row.push('"' + Nvh({ value: studentInfo.member_code }) + '"');
        row.push('"' + Nvh({ value: studentInfo.name }) + '"');
        row.push('"' + Nvh({ value: data.processed_date }) + '"');
        row.push('"' + Nvh({ value: data.closed_class }) + '"');
        row.push('"' + Nvh({ value: data.billing_amount }) + '"');
        row.push('"' + Nvh({ value: data.transfer_invoice_amount }) + '"');
        row.push('"' + Nvh({ value: data.transfer_amount }) + '"');
        row.push('"' + Nvh({ value: data.transfer_deposit_date }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_amount }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_date }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_amount2 }) + '"');
        row.push('"' + Nvh({ value: data.cash_deposit_date2 }) + '"');
        row.push(
          '"' +
            Nvh({ value: data.transfer_amount + data.cash_deposit_amount }) +
            '"'
        );
        row.push('"' + Nvh({ value: data.cumulative_excess_deficiency }) + '"');
        row.push('"' + Nvh({ value: data.billing_check }) + '"');
        csv.push(row.join(separator));
      });
    var csv_string = csv.join("\n");
    // Download it
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async table_id => {
    try {
      await download_table_as_csv(table_id, depositList); // Pass the data to the download function
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };
  return (
    <button
      className="btn btn-primary"
      onClick={() => {
        handleDownloadCSV(
          "入金処理_" + studentInfo.member_code + "_" + studentInfo.furigana
        );
      }}
    >
      <i className="fas fa-download fa-fw"></i>
      CSV
    </button>
  );
};

export default BillingTableCSVDownload;
