const { default: Nvh } = require("src/components/common/Nvh");

const StudentInfoHeader = ({ student }) => {
  return (
    <div
      className="row align-items-center m-0 mb-3 py-1 inputs_bg"
      // style={{ marginLeft: "0", marginRight: "0" }}
    >
      <div className="col-md-2 h-100">
        <strong>会員番号:</strong> &nbsp; &nbsp; {student?.member_code}
      </div>
      <div className="col-md-3 h-100">
        <strong>名前:</strong> {student?.name} &nbsp; [{student?.furigana}]
      </div>
      <div className="col-md-1 h-100">
        <strong>性別:</strong> {student?.gender}
      </div>
      <div className="col-md-6 h-100">
        <div className="row">
          <div className="col-2 text-md-start text-lg-end pt-1">
            <strong>備考:</strong>
          </div>
          <div className="col-10">
            <input
              type="text"
              value={Nvh({ value: student?.note })}
              className="form-control"
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentInfoHeader;
