import { useState } from "react";
import NavContext from "src/context/navContext";

export const NavProvider = ({ children }) => {
  const [currentMonth, setCurrentMonth] = useState("");

  const contextValues = { currentMonth, setCurrentMonth };

  return (
    <NavContext.Provider value={contextValues}>{children}</NavContext.Provider>
  );
};

export default NavProvider;
