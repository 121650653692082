import { useEffect, useState, useRef } from "react";
import { Button, Form, Tab, Table, Tabs } from "react-bootstrap";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { differenceInDays, differenceInYears, parse } from "date-fns";
import swal from "sweetalert";
import privateApi from "../../../services/privateApi";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import Option2 from "../../extrapage/Page19/Options2";
import moment from "moment/moment";
import Nvh from "../../common/Nvh";
import "./Student.css";
import ConvertTo from "../../utilis/ConvertKataka";
import ReactSelect from "react-select";
import RouteLeavingGuard from "../../Prompt/RouterLeavingGuard";
import { getCurrentMonth } from "../../../services/auth/token";
import { convertToHalfWidthFunction } from "../../common/HalfWidthKatakana/convertToKana";
import StudenPageAttendance from "./StudentPageAttendance";
import StudentInfoHeader from "src/components/extrapage/Page19/StudentInfoHeader";
import Billing from "src/components/extrapage/Page19/Billing";
import StudentClass from "src/components/extrapage/Page19/StudentClass";

function UpdateStudent() {
  const { id } = useParams();
  const history = useHistory();
  const addressListRef = useRef();
  const [errorList, setErrorList] = useState({});
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [joiningDate, setJoiningDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [leaveDate, setLeaveDate] = useState("");
  const [guardianOptions, setGuardianOptions] = useState([]);
  const [classes, setClasses] = useState([]);
  const [buses, setBuses] = useState([]);
  const [banks, setBanks] = useState([]);
  const [busId, setBusId] = useState("");
  const [student, setStudent] = useState({});
  const [remark, setRemark] = useState("");
  const [noticeDetails, setNoticeDetails] = useState([]);
  const [enableTabs, setEnableTabs] = useState(true); // useless state
  const [showValidation, setShowValidation] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const [areaLoading, setAreaLoading] = useState(false);
  const [showSugg, setShowSugg] = useState(false);
  const [page, setPage] = useState(1);
  const [age, setAge] = useState(null);
  const [classLevel, setClassLevel] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [readOnly, setReadOnly] = useState(true);
  const [trigger, setTrigger] = useState(true);
  const [key, setKey] = useState("tab1");
  const [relUpdateFlag, setRelUpdateFlag] = useState(false);
  const [selectedGuardian, setSelectedGuardian] = useState([]);
  const [editedName, setEditedName] = useState(false);
  const [whenState, updateWhenState] = useState(false);
  const [isBusSelectUsed, setIsBusSelectUsed] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action");
  const [currentURL, setCurrentURL] = useState(action);
  const tabKey = searchParams.get("key") ? searchParams.get("key") : "tab1";

  const [userId, setUserId] = useState();
  const excludedUserIds = ["tondabayashi", "abiko"];
  const mailHide = ["abiko"];
  const genderHide = ["abiko"];
  const [grantByPublic, setGrantByPublic] = useState(0);

  const cardBodyBackgroundColor = grantByPublic ? "#f1f5e0" : "";

  const [newStudents, setNewStudents] = useState({
    member_code: "",
    type: "",
    school_id: "",
    name: "",
    furigana: "",
    email: "",
    password: "",
    gender: "",
    birthday: startDate,
    district_code: "Default",
    zip_code: "",
    address_1: "",
    address_2: "",
    parent_name: "",
    phone_no_1: "",
    phone_note_1: "",
    phone_no_2: "",
    phone_note_2: "",
    fax_number: "",
    mail_address_1: "",
    mail_category_1: "",
    mail_note_1: "",
    mail_address_2: "",
    mail_category_2: "",
    mail_note_2: "",
    mail_note_3: "",
    family_member_id: "",
    charge_type_code: "",
    payment_type_code: "",
    deposit_item: "",
    account_no: "",
    account_holder: "",
    notice: "",
    note: "",
    joining_date: joiningDate,
    leave_date: "",
    bus_course_number: "",
    outbound_bus: "",
    return_bus: "",
    created_by: "",
    status: "",
    password_confirmation: "",
    relation: "",
    bank_code: "",
    branch_code: "",
    guardian_first_name: null,
    em_first_name: "",
    em_first_name_furigana: "",
    em_last_name: "",
    em_last_name_furigana: "",
    em_phone_no: "",
    guardian_name: "",
    guardian_furigana: "",
  });

  const [deposite, setDeposite] = useState({
    id: "",
    member_id: "",
    account_number: "",
    processed_date: "",
    billing_amount: "",
    transfer_invoice_amount: "",
    transfer_amount: "",
    transfer_deposit_date: "",
    cash_deposit_amount: "",
    cash_deposit_date: "",
    excess_deficiency: "",
    cumulative_excess_deficiency: "",
  });

  const handleInput = e => {
    updateWhenState(true);
    if (e.target?.name === "name") {
      setEditedName(true);
    }
    if (e.target && e.target.name) {
      setErrorList({});
      setNewStudents({ ...newStudents, [e.target.name]: e.target.value });
    }
  };

  //Image Upload Handler
  const [file, setFile] = useState("");
  const [imagedata, setImagedata] = useState("");

  const [bankId, setBankId] = useState();
  const [branchCode, setBranchCode] = useState();
  const [guardianList, setGuardianList] = useState([]);

  const handleChange = file => {
    setFile(URL.createObjectURL(file[0]));
    setImagedata(file[0]);
  };

  const getGuardians = async e => {
    const response = await privateApi.get("admin/guardians");
    if (response.status === 200) {
      setGuardianOptions(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const setGuadiansSelectedData = data => {
    const selectedOptions = data.map(item => ({
      value: item.relation_member_id,
      label: `${item.relation_member_code}-${item.relation_member_name}`,
      relation: item.relation ? item.relation : "",
    }));

    setSelectedGuardian(selectedOptions);

    setGuardianList(data);
  };

  const getStudentInfo = async id => {
    const response = await privateApi.get("admin/members/" + id);

    if (response.status === 200) {
      const data = response.data.data;

      setNewStudents(data);

      if (
        data.bank?.id == undefined ||
        data.bank?.id == null ||
        data.bank?.id == ""
      ) {
        setNewStudents({ ...data, payment_type_code: "新規" });
      }

      setGuadiansSelectedData(data.family_members);
      setStudent(data);
      setBusId(data?.bus?.id && parseInt(data?.bus?.id));
      setBankId(data.bank?.id);
      setBranchCode(data.branch_code);
      setNoticeDetails(data.notice);
      setRemark(data.note);
      setStartDate(data.birthday);
      calculateAge(data.birthday);
      setJoiningDate(data.joining_date);
      setLeaveDate(data.leave_date);
      setShowValidation(true);
      setGrantByPublic(data.grant_by_public);
    } else {
      console.log("Server Error");
    }
  };

  const getGuardianlist = async () => {
    const response = await privateApi.get(
      `admin/get-members-list?type=Regular`
    );
    if (response.status === 200) {
      setGuardianOptions(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const guardians = guardianOptions.map(data => ({
    value: data.id,
    label: data.member_code + "-" + data.name,
  }));

  const getGenerelRes = async e => {
    const month = getCurrentMonth();

    const response = await privateApi.get(
      `admin/general-resources?type=bus,bank,member_code&processed_date=${moment(month).format("YYYY-MM")}`
    );
    if (response.status === 200) {
      setBuses(response.data.bus);
      setBanks(response.data.bank);
    } else {
      console.log("Server Error");
    }
  };

  let initialBank = banks.find(bank => bank.id == bankId);
  let initialBranchName = initialBank ? initialBank.branch_name : "";

  const bankHandler = bank_code => {
    setBankId(bank_code);

    let pType = newStudents.payment_type_code;

    if (pType === "新規" && bank_code !== "") {
      pType = "振替";
      setNewStudents({ ...newStudents, payment_type_code: pType });
    }
  };

  const getAssignedClassesNow = async id => {
    const formData = {
      member_id: id,
      processed_date: moment(new Date()).format("YYYY-MM"),
    };

    const response = await privateApi.post(
      "admin/get-member-classes",
      formData
    );

    if (response.status === 200) {
      setClasses(response.data.data.classes);
    } else {
      console.log("Server Error");
    }
  };

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [addressOptions]);

  useEffect(() => {
    getStudentInfo(id);
    getGuardians();
    getGenerelRes();
    getAssignedClassesNow(id);
    setReadOnly(action === "view");
    setKey(tabKey);
    getGuardianlist();
  }, [id, action, tabKey]);

  const handleUrlChange = url => {
    setCurrentURL(url);
    const newUrl = `/admin/student/details/${id}?action=${url}&key=${key}`;
    history.replace(newUrl);
  };

  const convertAndSetFurigana = async (target_field, given_field, type) => {
    try {
      const convertedText = await ConvertTo(newStudents[given_field], type);
      if (convertedText) {
        setNewStudents({
          ...newStudents,
          [`${target_field}`]: convertedText,
        });
      } else {
        setNewStudents({
          ...newStudents,
          [`${target_field}`]: "",
        });
      }
    } catch (error) {
      console.error(`Error converting ${given_field}`, error);
    }
  };

  useEffect(() => {
    if (editedName) {
      const fetchData = async () => {
        if (newStudents.name) {
          convertAndSetFurigana("furigana", "name", "katakana");
        } else {
          setNewStudents({
            ...newStudents,
            furigana: "",
          });
        }
      };

      const timer = setTimeout(fetchData, 500);

      return () => clearTimeout(timer);
    }
  }, [newStudents.name]);

  useEffect(() => {
    const handleKeyPress = event => {
      if (key === "tab1") {
        switch (event.key) {
          case "F8":
            updateFormSubmit(2);
            break;
          case "F9":
            updateFormSubmit(1);
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [newStudents, key]);

  // note praveen extract this logic into a custom hook

  const [keysPressed, setKeyPressed] = useState({});

  useEffect(() => {
    const handleKeyDown = e => {
      const eventKey = e.key.toLowerCase();
      const tab = { x: "tab1", c: "tab2", a: "tab3", s: "tab4" }[eventKey];

      if (eventKey === "alt") {
        if (!keysPressed["alt"]) setKeyPressed({ [eventKey]: true });
        e.preventDefault();
      }

      if (keysPressed["alt"] && tab) {
        setKey(tab);
      }
    };

    const handleKeyUp = e => {
      const eventKey = e.key.toLowerCase();
      if (eventKey === "alt") {
        setKeyPressed({});
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [keysPressed]);

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.em_first_name) {
        convertAndSetFurigana(
          "em_first_name_furigana",
          "em_first_name",
          "katakana"
        );
      } else {
        setNewStudents({
          ...newStudents,
          em_first_name_furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.em_first_name]);

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.em_last_name) {
        convertAndSetFurigana(
          "em_last_name_furigana",
          "em_last_name",
          "katakana"
        );
      } else {
        setNewStudents({
          ...newStudents,
          em_last_name_furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.em_last_name]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === "F2") {
        currentURL === "view"
          ? handleUrlChange("update")
          : handleUrlChange("view");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [id, history, currentURL]);

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.guardian_name) {
        convertAndSetFurigana("guardian_furigana", "guardian_name", "katakana");
      } else {
        setNewStudents({
          ...newStudents,
          guardian_furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.guardian_name]);

  //Guradian Input Handler
  const [familyMembers, setFamilyMembers] = useState([]);

  useEffect(() => {
    const initialFormData = selectedGuardian.map(guard => {
      const existingData = familyMembers.find(
        data => data.value === guard.value
      );
      return (
        existingData || {
          relation: guard.relation ? guard.relation : "",
          value: guard.value,
        }
      );
    });
    setFamilyMembers(initialFormData);
  }, [selectedGuardian]);

  const handleInputChange = (index, event) => {
    setRelUpdateFlag(true);
    const updatedFormData = [...familyMembers];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [event.target.name]: event.target.value,
    };
    setFamilyMembers(updatedFormData);
  };

  const getBusId = () => {
    return buses.find(bus => {
      if (isBusSelectUsed) return bus?.id === parseInt(busId);
      return bus?.bus_course_number === student?.bus?.bus_course_number;
    })?.id;
  };

  const updateFormSubmit = async status => {
    updateWhenState(false);
    setLoading(true);

    setStudent(newStudents);

    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("type", newStudents.type);
    formData.append("guardian_photo", imagedata);

    formData.append("member_code", newStudents.member_code);
    formData.append("name", newStudents.name);
    formData.append("furigana", newStudents.furigana);
    formData.append("email", newStudents.email);
    formData.append("gender", newStudents.gender);
    formData.append("birthday", startDate);
    formData.append("district_code", newStudents.district_code);
    formData.append("zip_code", newStudents.zip_code);
    formData.append("address_1", newStudents.address_1);
    formData.append("address_2", newStudents.address_2);
    formData.append("phone_no_1", newStudents.phone_no_1);
    formData.append("phone_note_1", newStudents.phone_note_1);
    formData.append("phone_no_2", newStudents.phone_no_2);
    formData.append("phone_note_2", newStudents.phone_note_2);
    formData.append("fax_number", newStudents.fax_number);
    formData.append(
      "mail_address_1",
      newStudents.mail_address_1 ? newStudents.mail_address_1 : ""
    );
    formData.append("mail_category_1", newStudents.mail_category_1);
    formData.append("mail_note_1", newStudents.mail_note_1);
    formData.append("mail_address_2", newStudents.mail_address_2);
    formData.append("mail_category_2", newStudents.mail_category_2);
    formData.append("mail_note_2", newStudents.mail_note_2);
    formData.append("mail_address_3", newStudents.mail_address_3);
    formData.append("mail_category_3", newStudents.mail_category_3);
    formData.append("mail_note_3", newStudents.mail_note_3);
    formData.append("guardian_first_name", newStudents.guardian_first_name);

    formData.append("em_first_name", newStudents.em_first_name);
    formData.append(
      "em_first_name_furigana",
      newStudents.em_first_name_furigana
    );
    formData.append("em_last_name", newStudents.em_last_name);
    formData.append("em_last_name_furigana", newStudents.em_last_name_furigana);
    formData.append("em_phone_no", newStudents.em_phone_no);

    if (
      newStudents.family_member_id != null &&
      newStudents.family_member_id != "undefined"
    ) {
      formData.append("family_member_id", newStudents.family_member_id);
    } else {
      formData.append("family_member_id", null);
    }

    formData.append("relation", newStudents.relation);
    formData.append("joining_date", newStudents.joining_date);
    if (newStudents.leave_date !== null) {
      formData.append("leave_date", newStudents.leave_date);
    }
    formData.append("bus_course_number", getBusId());
    formData.append("outbound_bus", newStudents.outbound_bus);
    formData.append("return_bus", newStudents.return_bus);
    formData.append("charge_type_code", newStudents.charge_type_code);
    formData.append("payment_type_code", newStudents.payment_type_code);
    formData.append("bank_code", bankId);
    formData.append("branch_code", branchCode);
    formData.append("deposit_item", newStudents.deposit_item);
    formData.append("account_no", newStudents.account_no);
    formData.append("account_holder", newStudents.account_holder);
    formData.append("notice", noticeDetails ? noticeDetails : "");
    formData.append("note", remark ? remark : "");
    formData.append("guardian_name", newStudents.guardian_name);
    formData.append("guardian_furigana", newStudents.guardian_furigana);
    formData.append("family_members", JSON.stringify(familyMembers));
    formData.append("grant_by_public", grantByPublic);

    const response = await privateApi.formUpdate(
      `admin/members/${id}`,
      formData
    );

    if (response.status === 200) {
      updateWhenState(false);
      swal("会員情報を更新しました", "", "success");
      setLoading(false);
      if (status == 1) {
        history.push("/admin/student");
      } else {
        setKey("tab2");
      }
    } else {
      swal("内容に問題があります", response.data.message, "error");
      setErrorList(response.data.errors);
      setLoading(false);
    }
  };

  const calculateAge = birthdate => {
    if (!birthdate) {
      setAge(null);
      return;
    }

    const today = new Date();
    const birthDate = parse(birthdate, "yyyy-MM-dd", new Date());
    const diffInDays =
      differenceInDays(today, birthDate) -
      Math.floor(differenceInDays(today, birthDate) / 365 / 4);

    const years = Math.floor(diffInDays / 365);
    // const months = Math.floor((diffInDays % 365) / 30);
    // const days = diffInDays - years * 365 - months * 30;

    //calculate which class
    var year_now = today.getFullYear();
    var month_now = today.getMonth();
    if (month_now == "0" || month_now == "1" || month_now == "2") {
      year_now = Math.floor(year_now - 1);
    }
    const april_day = new Date(year_now + "-04-01");

    const years_for_class = differenceInYears(april_day, birthDate);

    var class_of_the_student = "";

    if (years_for_class == "6") {
      class_of_the_student = "小1";
    } else if (years_for_class == "7") {
      class_of_the_student = "小2";
    } else if (years_for_class == "8") {
      class_of_the_student = "小3";
    } else if (years_for_class == "9") {
      class_of_the_student = "小4";
    } else if (years_for_class == "10") {
      class_of_the_student = "小5";
    } else if (years_for_class == "11") {
      class_of_the_student = "小6";
    } else if (years_for_class == "12") {
      class_of_the_student = "中1";
    } else if (years_for_class == "13") {
      class_of_the_student = "中2";
    } else if (years_for_class == "14") {
      class_of_the_student = "中3";
    }

    setAge(`${years} 才`);
    setClassLevel(`${class_of_the_student}`);
  };

  const handleDateChange = date => {
    if (date) {
      setStartDate(date);
      setNewStudents({ ...newStudents, birthday: date });
      calculateAge(date);
    }
  };

  const isFetching = useRef(false);

  const fetchAddressOptions = async (input, currentPage) => {
    if (currentPage > 0) {
      setAreaLoading(true);
    }

    if (isFetching.current) return;
    isFetching.current = true;

    try {
      const response = await privateApi.post(
        `admin/city-search?page=${currentPage + 1}`,
        input
      );
      if (response.status === 200) {
        const newOptions = await response.data.data;
        setAddressOptions(prevOptions => [...prevOptions, ...newOptions]);
        setPage(currentPage + 1);
        setAreaLoading(false);
        setShowSugg(true);
      }
    } catch (error) {
      console.error("Error fetching address options:", error);
    } finally {
      isFetching.current = false;
    }

    setAreaLoading(false);
  };

  const zipCodeHandler = value => {
    setPage(1);
    setAddressOptions([]);
    const data = {
      postal_code: value,
      per_page: 15,
    };
    fetchAddressOptions(data, 0);
  };

  const handleKeyDown = e => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex(prevIndex =>
        prevIndex < addressOptions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      e.preventDefault();
      addressHandler(addressOptions[highlightedIndex]);
    }
    if (addressListRef.current) {
      const itemHeight = 65;
      const scrollTarget =
        highlightedIndex * itemHeight - addressListRef.current.clientHeight / 2;
      const animateScroll = time => {
        const currentTime = time || 0;
        const duration = 500;
        const progress = Math.min(1, currentTime / duration);
        if (addressListRef.current) {
          addressListRef.current.scrollTop =
            addressListRef.current.scrollTop +
            (scrollTarget - addressListRef.current.scrollTop) * progress;
          if (progress < 1) {
            window.requestAnimationFrame(animateScroll);
          }
        }
      };
      window.requestAnimationFrame(animateScroll);
    }
  };

  const handleScroll = () => {
    const ulElement = document.querySelector(".list-box");
    if (ulElement) {
      const isScrolledToBottom =
        ulElement.scrollHeight - ulElement.clientHeight <=
        ulElement.scrollTop + 1;
      if (isScrolledToBottom) {
        const data = {
          postal_code: newStudents.zip_code,
          per_page: 5,
        };
        fetchAddressOptions(data, page);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [newStudents.zip_code, page]);

  useEffect(() => {
    const ulElement = document.querySelector(".list-box");
    if (ulElement) {
      ulElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ulElement) {
        ulElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [newStudents.zip_code, page]);

  const addressHandler = value => {
    setShowSugg(false);
    setNewStudents({
      ...newStudents,
      address_1: value.city + value.address,
      zip_code: value.postal_code,
    });
  };

  const [menuIsOpen, setMenuIsOpen] = useState();

  const customFilterOption = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const onInputChange = (inputValue, { action, prevInputValue }) => {
    if (action === "input-change") return inputValue;
    if (action === "menu-close") {
      if (prevInputValue) setMenuIsOpen(true);
      else setMenuIsOpen(undefined);
    }
    return prevInputValue;
  };

  useEffect(() => {
    const userData = localStorage.getItem("school_slug");
    if (userData) {
      setUserId(userData);
    }
  }, []);

  const renderClassTimes = (classTimes, day) => {
    const timeEntry = classTimes.find(ct => ct.week_days === day);
    return timeEntry ? `${timeEntry.start_time} ~` : "";
  };

  const handleGrantChange = e => {
    setGrantByPublic(e.target.checked ? 1 : 0);
  };

  const formSection = (
    <span
      className={`${showValidation ? "was-validated" : "needs-validation"}`}
    >
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              会員番号: <span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                className="form-control"
                type="text"
                placeholder={!readOnly ? "000" : ""}
                name="member_id"
                onChange={handleInput}
                value={Nvh({ value: newStudents.member_code })}
                readOnly
              />
            </div>
            <label className="col-sm-3 col-form-label text-sm-end">
              助成金利用者: <span className="text-white">-</span>
            </label>
            <div style={{ display: "flex", flex: 1, maxWidth: "45px" }}>
              <input
                style={{ height: "18px", marginTop: "7px" }}
                className="form-control"
                type="checkbox"
                placeholder={!readOnly ? "000" : ""}
                onChange={handleGrantChange}
                checked={grantByPublic}
                readOnly={readOnly}
                disabled={readOnly}
              />
            </div>
          </div>
          <div className="form-group mb-2 row"></div>
          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              名前: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder={!readOnly ? "名前" : ""}
                name="name"
                onChange={handleInput}
                value={Nvh({ value: newStudents.name })}
                className={`form-control ${
                  errorList?.name ? "is-invalid" : ""
                }`}
                required
                readOnly={readOnly}
                style={{ fontSize: "21px" }}
              />
              <div className="invalid-feedback">
                {errorList?.name ? errorList?.name : ""}
              </div>
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              フリガナ: <span className="required">*</span>
            </label>
            <div className="col-sm-3">
              <input
                type="text"
                placeholder={!readOnly ? "フリガナ" : ""}
                name="furigana"
                onChange={handleInput}
                value={Nvh({ value: newStudents.furigana })}
                className={`form-control ${
                  errorList?.furigana ? "is-invalid" : ""
                }`}
                required
                readOnly={readOnly}
              />
              <div className="invalid-feedback">
                {errorList?.furigana ? errorList?.furigana : ""}
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end mt-2">
              性別: <span className="required">*</span>
            </label>
            <div className="col-sm-4 pt-2">
              {readOnly ? (
                <>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="validationFormCheck2"
                      name="gender"
                      value="男"
                      checked={newStudents.gender === "男"}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="validationFormCheck2"
                    >
                      男
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="validationFormCheck2"
                      name="gender"
                      value="女"
                      checked={newStudents.gender === "女"}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="validationFormCheck2"
                    >
                      女
                    </label>

                    {!genderHide.includes(userId) && (
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="validationFormCheck2"
                          name="gender"
                          value="未"
                          checked={newStudents.gender === "未"}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          未
                        </label>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="validationFormCheck2"
                      name="gender"
                      onChange={handleInput}
                      value="男"
                      checked={newStudents.gender === "男"}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="validationFormCheck2"
                    >
                      男
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="validationFormCheck2"
                      name="gender"
                      onChange={handleInput}
                      value="女"
                      checked={newStudents.gender === "女"}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="validationFormCheck2"
                    >
                      女
                    </label>
                  </div>

                  {!genderHide.includes(userId) && (
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="validationFormCheck2"
                        name="gender"
                        onChange={handleInput}
                        value="未"
                        checked={newStudents.gender === "未"}
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="validationFormCheck2"
                      >
                        未
                      </label>
                    </div>
                  )}
                </>
              )}
              <div className="invalid-feedback">
                {errorList?.gender ? errorList?.gender : ""}
              </div>
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              生年月日: <span className="required">*</span>
            </label>
            <div className="col-sm-3 ">
              <div className="">
                <input
                  type="date"
                  className={`form-control w-100 ${
                    errorList?.birthday ? "is-invalid" : ""
                  }`}
                  value={startDate}
                  required
                  onChange={e => {
                    setStartDate(e.target.value);
                    calculateAge(e.target.value);
                  }}
                  max="3090-12-31"
                  readOnly={readOnly}
                />

                <div className="small text-danger">
                  {errorList?.birthday ? errorList?.birthday : ""}
                </div>
              </div>
              <div className="age-view">
                <div className="text-success">
                  {age} &nbsp;&nbsp; {classLevel}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              入会日: <span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                type="date"
                className="w-100 form-control pointer"
                value={joiningDate ? joiningDate : ""}
                onChange={e => {
                  setJoiningDate(e.target.value);
                  setNewStudents({
                    ...newStudents,
                    joining_date: e.target.value,
                  });
                }}
                max="3090-12-31"
                readOnly={readOnly}
              />
            </div>

            <label className="col-sm-3 col-form-label text-sm-end">
              退校日: <span className="text-white">-</span>
            </label>
            <div className="col-sm-3">
              <input
                type="date"
                className="w-100 form-control pointer"
                value={leaveDate ? leaveDate : ""}
                onChange={e => {
                  setLeaveDate(e.target.value);
                  setNewStudents({
                    ...newStudents,
                    leave_date: e.target.value,
                  });
                }}
                max="3090-12-31"
                readOnly={readOnly}
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              郵便番号: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <div className="select-wrapper">
                <input
                  type="text"
                  placeholder={!readOnly ? "郵便番号" : ""}
                  autoComplete="off"
                  name="zip_code"
                  onChange={e => {
                    setNewStudents({
                      ...newStudents,
                      zip_code: e.target.value,
                      address_1: "",
                    });
                    zipCodeHandler(e.target.value);
                  }}
                  value={Nvh({ value: newStudents.zip_code })}
                  onKeyDown={handleKeyDown}
                  className={`form-control ${
                    errorList?.zip_code ? "is-invalid" : ""
                  }`}
                  required
                  readOnly={readOnly}
                />

                {addressOptions.length > 0 && showSugg ? (
                  <ul
                    ref={addressListRef}
                    className="shadow-sm card list-box"
                    style={{
                      listStyle: "none",
                      maxHeight: "200px",
                      overflowY: "auto",
                      padding: "0px",
                    }}
                  >
                    {addressOptions.map((add, index) => (
                      <li
                        className={`area-list-item ${
                          highlightedIndex === index ? "highlighted" : ""
                        }`}
                        key={index}
                        onClick={() => addressHandler(add)}
                      >
                        {add.postal_code} ({add.city} {add.address})
                      </li>
                    ))}

                    {areaLoading && <li className="text-center">...</li>}
                  </ul>
                ) : (
                  ""
                )}
              </div>

              <div className="invalid-feedback">
                {errorList?.zip_code ? errorList?.zip_code : ""}
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              住所1: <span className="required">*</span>
            </label>
            <div className="col-sm-5">
              <input
                type="text"
                placeholder={!readOnly ? "住所 1" : ""}
                name="address_1"
                onChange={handleInput}
                value={Nvh({ value: newStudents.address_1 })}
                className={`form-control ${
                  errorList?.address_1 ? "is-invalid" : ""
                }`}
                required
                readOnly={readOnly}
              />
              <div className="invalid-feedback">
                {errorList?.address_1 ? errorList?.address_1 : ""}
              </div>
            </div>
          </div>
          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              住所2:{" "}
              <span style={{ visibility: "hidden" }} className="required">
                *
              </span>
            </label>
            <div className="col-sm-5">
              <input
                type="text"
                className="form-control"
                placeholder={!readOnly ? "住所 2" : ""}
                name="address_2"
                onChange={handleInput}
                value={Nvh({ value: newStudents.address_2 })}
                readOnly={readOnly}
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              電話1: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder={!readOnly ? "電話番号1" : ""}
                name="phone_no_1"
                onChange={handleInput}
                value={Nvh({ value: newStudents.phone_no_1 })}
                className={`form-control ${
                  errorList?.phone_no_1 ? "is-invalid" : ""
                }`}
                required
                readOnly={readOnly}
              />
              <div className="invalid-feedback">
                {errorList?.phone_no_1 ? errorList?.phone_no_1 : ""}
              </div>
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">備考:</label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder={!readOnly ? "電話備考1" : ""}
                name="phone_note_1"
                onChange={handleInput}
                value={Nvh({ value: newStudents.phone_note_1 })}
                readOnly={readOnly}
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              電話2: <span className="text-white">-</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder={!readOnly ? "電話番号2" : ""}
                name="phone_no_2"
                onChange={handleInput}
                value={Nvh({ value: newStudents.phone_no_2 })}
                readOnly={readOnly}
              />
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">備考:</label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control"
                placeholder={!readOnly ? "電話備考2" : ""}
                name="phone_note_2"
                onChange={handleInput}
                value={Nvh({ value: newStudents.phone_note_2 })}
                readOnly={readOnly}
              />
            </div>
          </div>

          {!mailHide.includes(userId) && (
            <>
              <div className="form-group mb-2 row">
                <label className="col-sm-3 col-form-label text-sm-end">
                  メール1: <span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <input
                    type="email"
                    placeholder={!readOnly ? "メールアドレス_1" : ""}
                    name="mail_address_1"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.mail_address_1 })}
                    className={`form-control ${
                      errorList?.mail_address_1 ? "is-invalid" : ""
                    }`}
                    readOnly={readOnly}
                  />
                  <div className="invalid-feedback">
                    {errorList?.mail_address_1 ? errorList?.mail_address_1 : ""}
                  </div>
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  備考:
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={!readOnly ? "メール備考1" : ""}
                    name="mail_note_1"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.mail_note_1 })}
                    readOnly={readOnly}
                  />
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-3 col-form-label text-sm-end">
                  メール2: <span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder={!readOnly ? "メールアドレス_2" : ""}
                    name="mail_address_2"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.mail_address_2 })}
                    readOnly={readOnly}
                  />
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  備考:
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={!readOnly ? "メール備考2" : ""}
                    name="mail_note_2"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.mail_note_2 })}
                    readOnly={readOnly}
                  />
                </div>
              </div>
            </>
          )}

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              バスコース番号: <span className="text-white">-</span>
            </label>

            <div className="col-sm-4">
              <select
                className="form-control"
                placeholder={!readOnly ? "バスコース番号" : ""}
                name="bus_course_number"
                onChange={e => {
                  setBusId(e.target.value);
                  setIsBusSelectUsed(true);
                }}
                value={getBusId()}
                disabled={readOnly}
              >
                <option value="">バスの選択</option>
                {buses.map((bus, i) => (
                  <option key={i} value={bus.id}>
                    {bus.bus_course_number} ({bus.stop_name})
                  </option>
                ))}
              </select>
            </div>

            <div className="col-5 row bus-seat">
              <label className="col-2 col-form-label text-sm-end">
                往路:<span className="text-white">-</span>
              </label>
              <div className="col-sm-2 pr-0">
                <input
                  type="number"
                  className="form-control"
                  name="outbound_bus"
                  onChange={handleInput}
                  value={Nvh({ value: newStudents.outbound_bus })}
                  readOnly={readOnly}
                />
              </div>
              <div className="col-2 sz-15">人</div>
              <label className="col-sm-2 col-form-label text-sm-end">
                復路:
              </label>
              <div className="col-sm-2 pr-0">
                <input
                  type="number"
                  className="form-control"
                  name="return_bus"
                  onChange={handleInput}
                  value={Nvh({ value: newStudents.return_bus })}
                  readOnly={readOnly}
                />
              </div>
              <div className="col-2 sz-15">人</div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-3 col-form-label text-sm-end">
              家族会員: <span className="text-white">-</span>
            </label>
            <div className="col-sm-9">
              {readOnly ? (
                <ReactSelect
                  className="shadow-sm pointer-null"
                  value={selectedGuardian}
                  options={guardians}
                  isMulti
                  menuIsOpen={false}
                  filterOption={customFilterOption}
                  disabled
                  readOnly
                />
              ) : (
                <ReactSelect
                  className="shadow-sm"
                  value={selectedGuardian}
                  placeholder="選択"
                  options={guardians}
                  onChange={setSelectedGuardian}
                  closeMenuOnSelect={false}
                  isMulti
                  hideSelectedOptions={false}
                  onInputChange={onInputChange}
                  menuIsOpen={menuIsOpen}
                  filterOption={customFilterOption}
                />
              )}
            </div>
          </div>

          {selectedGuardian.length > 0 && (
            <div className="form-group mb-2 row">
              <label className="col-sm-3 col-form-label text-sm-end"></label>
              <div className="table-responsive col-sm-9">
                <Table striped bordered hover>
                  <tbody>
                    {selectedGuardian.map((guard, i) => (
                      <tr key={i}>
                        <td>{guard.label}</td>
                        <td>
                          <input
                            type="text"
                            placeholder="関係"
                            className="form-control w-50"
                            name="relation"
                            // value={guard.relation}
                            value={
                              relUpdateFlag
                                ? familyMembers[i]?.relation
                                : guard.relation
                            }
                            onChange={event =>
                              handleInputChange(i, event, guard.value)
                            }
                            readOnly={readOnly}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}

          {newStudents.family_member_id == 11 ? (
            <div className="">
              <div className="row bg-light py-2">
                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_first_name"
                >
                  <Form.Label>保護者の名前</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={!readOnly ? "保護者の名前" : ""}
                    name="guardian_first_name"
                    onChange={handleInput}
                    required
                    value={Nvh({ value: newStudents.guardian_first_name })}
                    readOnly={readOnly}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_last_name"
                >
                  <Form.Label>保護者の姓</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={!readOnly ? "保護者の姓" : ""}
                    name="guardian_last_name"
                    onChange={handleInput}
                    required
                    value={Nvh({ value: newStudents.guardian_last_name })}
                    readOnly={readOnly}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_phone"
                >
                  <Form.Label>保護者の電話</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={!readOnly ? "保護者の電話" : ""}
                    name="guardian_phone"
                    required
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.guardian_phone })}
                    readOnly={readOnly}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_address"
                >
                  <Form.Label>保護者の住所</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={!readOnly ? "保護者の住所" : ""}
                    name="guardian_address"
                    required
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.guardian_address })}
                    readOnly={readOnly}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_email"
                >
                  <Form.Label>保護者のメール</Form.Label>
                  <Form.Control
                    type="mail"
                    placeholder={!readOnly ? "保護者のメール" : ""}
                    name="guardian_email"
                    onChange={handleInput}
                    required
                    value={Nvh({ value: newStudents.guardian_email })}
                    readOnly={readOnly}
                  />
                </Form.Group>

                <Form.Group
                  className="col-md-3 mb-3"
                  controlId="formBasicPassword"
                >
                  <Form.Label>保護者の写真</Form.Label>

                  <div className="img-box">
                    <input
                      onChange={e => handleChange(e.target.files)}
                      multiple={false}
                      type="file"
                      className="form-control"
                      readOnly={readOnly}
                    />
                  </div>
                </Form.Group>

                <Form.Group
                  className="mb-3 col-md-3"
                  controlId="guardian_photo"
                >
                  <div className="mb-1">
                    {file ? (
                      <img
                        src={file}
                        height={150}
                        width={150}
                        className="avater-img-src"
                        alt="logo"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="col-md-6">
          <div className="row">
            <Form.Label>
              <h5>クラス:</h5>
            </Form.Label>

            <div className="table-responsive">
              <Table
                striped
                bordered
                hover
                className="table text-left"
                responsive="lg"
              >
                <thead>
                  <tr>
                    <th>クラス名</th>
                    <th>級</th>
                    <th>月</th>
                    <th>火</th>
                    <th>水</th>
                    <th>木</th>
                    <th>金</th>
                    <th>土</th>
                    <th>日</th>
                  </tr>
                </thead>
                <tbody>
                  {classes.map((classItem, index) => (
                    <tr key={index}>
                      <td>{classItem.class_name}</td>
                      <td>{classItem.rank}</td>
                      <td>{renderClassTimes(classItem.class_times, "月")}</td>
                      <td>{renderClassTimes(classItem.class_times, "火")}</td>
                      <td>{renderClassTimes(classItem.class_times, "水")}</td>
                      <td>{renderClassTimes(classItem.class_times, "木")}</td>
                      <td>{renderClassTimes(classItem.class_times, "金")}</td>
                      <td>{renderClassTimes(classItem.class_times, "土")}</td>
                      <td>{renderClassTimes(classItem.class_times, "日")}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="form-group mt-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              申し送り事項 :
            </label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                maxLength="100"
                rows={2}
                onChange={e => setNoticeDetails(e.target.value)}
                value={Nvh({ value: noticeDetails })}
                placeholder={
                  !readOnly
                    ? "申し送り事項を記入してください（100文字以内）"
                    : ""
                }
                readOnly={readOnly}
              ></textarea>
              <div className="invalid-feedback">
                {errorList?.notice ? errorList?.notice : ""}
              </div>
            </div>
          </div>

          <div className="form-group mt-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">備考:</label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                maxLength="100"
                rows={2}
                onChange={e => setRemark(e.target.value)}
                value={Nvh({ value: remark })}
                placeholder={
                  !readOnly ? "備考を記入してください（100文字以内）" : ""
                }
                readOnly={readOnly}
              ></textarea>
              <div className="invalid-feedback">
                {errorList?.note ? errorList?.note : ""}
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end"></label>
            <div className="col-sm-4">
              <h4 className="my-1">保護者</h4>
            </div>
            <label className="col-sm-6"></label>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">氏名:</label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder={!readOnly ? "保護者の氏名" : ""}
                className="form-control"
                name="guardian_name"
                onChange={handleInput}
                value={Nvh({ value: newStudents.guardian_name })}
                readOnly={readOnly}
              />
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              フリガナ:
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder={!readOnly ? "保護者の氏名：フリガナ" : ""}
                className="form-control"
                name="guardian_furigana"
                onChange={handleInput}
                value={Nvh({ value: newStudents.guardian_furigana })}
                readOnly={readOnly}
              />
            </div>
          </div>

          {!excludedUserIds.includes(userId) && (
            <>
              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end"></label>
                <div className="col-sm-4">
                  <h4 className="my-1">緊急連絡</h4>
                </div>
                <label className="col-sm-6"></label>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  姓:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    placeholder={!readOnly ? "緊急時の名" : ""}
                    className="form-control"
                    name="em_first_name"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.em_first_name })}
                    readOnly={readOnly}
                  />
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  フリガナ:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    placeholder={!readOnly ? "姓：フリガナ" : ""}
                    className="form-control"
                    name="em_first_name_furigana"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.em_first_name_furigana })}
                    readOnly={readOnly}
                  />
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  {" "}
                  名:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    placeholder={!readOnly ? "緊急時の姓" : ""}
                    className="form-control"
                    name="em_last_name"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.em_last_name })}
                    readOnly={readOnly}
                  />
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  フリガナ:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    placeholder={!readOnly ? "名：フリガナ" : ""}
                    className="form-control"
                    name="em_last_name_furigana"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.em_last_name_furigana })}
                    readOnly={readOnly}
                  />
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  電話:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    placeholder={!readOnly ? "緊急電話番号" : ""}
                    className="form-control"
                    name="em_phone_no"
                    onChange={handleInput}
                    value={Nvh({ value: newStudents.em_phone_no })}
                    readOnly={readOnly}
                  />
                </div>

                <div className="col-sm-6"></div>
              </div>
            </>
          )}

          {newStudents.type === "Regular" && (
            <>
              <hr></hr>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  料金種別: <span className="required">*</span>
                </label>
                <div className="col-sm-10">
                  {readOnly ? (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          value="通常"
                          checked={newStudents.charge_type_code == "通常"}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          通常
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          value="特別１"
                          checked={newStudents.charge_type_code === "特別１"}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          特別１
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          value="特別２"
                          checked={newStudents.charge_type_code === "特別２"}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          特別2
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          value="無料"
                          checked={newStudents.charge_type_code === "無料"}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          無料
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="validationFormCheck2"
                          name="charge_type_code"
                          onChange={handleInput}
                          value="通常"
                          checked={newStudents.charge_type_code == "通常"}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          通常
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="validationFormCheck2"
                          name="charge_type_code"
                          onChange={handleInput}
                          value="特別１"
                          checked={newStudents.charge_type_code === "特別１"}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          特別１
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="validationFormCheck2"
                          name="charge_type_code"
                          onChange={handleInput}
                          value="特別２"
                          checked={newStudents.charge_type_code === "特別２"}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          特別2
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="validationFormCheck2"
                          name="charge_type_code"
                          onChange={handleInput}
                          value="無料"
                          checked={newStudents.charge_type_code === "無料"}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          無料
                        </label>
                      </div>
                    </>
                  )}

                  <div className="invalid-feedback">
                    {errorList?.charge_type_code
                      ? errorList?.charge_type_code
                      : ""}
                  </div>
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  納付区分:<span className="text-white">-</span>
                </label>
                <div className="col-sm-10">
                  {readOnly ? (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={newStudents.payment_type_code === "振替"}
                          value="振替"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          振替
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={newStudents.payment_type_code === "新規"}
                          value="新規"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          新規
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={newStudents.payment_type_code === "現金"}
                          value="現金"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          現金
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={newStudents.payment_type_code === "振込"}
                          value="振込"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="validationFormCheck2"
                        >
                          振込
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="payment_type_code"
                          checked={newStudents.payment_type_code === "振替"}
                          onChange={handleInput}
                          value="振替"
                          readOnly={readOnly}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          振替
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="payment_type_code"
                          checked={newStudents.payment_type_code === "新規"}
                          onChange={handleInput}
                          value="新規"
                          readOnly={readOnly}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          新規
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="payment_type_code"
                          checked={newStudents.payment_type_code === "現金"}
                          onChange={handleInput}
                          value="現金"
                          readOnly={readOnly}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          現金
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="payment_type_code"
                          checked={newStudents.payment_type_code === "振込"}
                          onChange={handleInput}
                          value="振込"
                          readOnly={readOnly}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          振込
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {newStudents.type === "Regular" && (
            <>
              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end"></label>
                <div className="col-sm-4">
                  <h4 className="my-1">銀行情報</h4>
                </div>
                <label className="col-sm-6"></label>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  銀行コード:<span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <select
                    name="bank_code"
                    className="form-control"
                    onChange={event => bankHandler(event.target.value)}
                    value={newStudents.type === "Regular" ? bankId : ""}
                    disabled={newStudents.type === "Short Term" || readOnly}
                  >
                    <option value="">銀行を選択</option>
                    {banks.map((bank, i) => (
                      <option
                        key={i}
                        value={bank.id}
                        data-branch-code={bank.branch_code}
                        data-branch-name={bank.branch_name}
                      >
                        {bank.bank_name}
                      </option>
                    ))}
                  </select>
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  支店コード:
                </label>
                <div className="col-sm-4">
                  <input
                    type="tel"
                    name="branch_code"
                    className="form-control"
                    placeholder={!readOnly ? "支店コード" : ""}
                    pattern="[0-9]+"
                    value={
                      newStudents.type === "Regular"
                        ? Nvh({ value: branchCode })
                        : ""
                    }
                    onChange={e => setBranchCode(e.target.value)}
                    disabled={newStudents.type === "Short Term" || readOnly}
                  />
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  預金種別:<span className="text-white">-</span>
                </label>
                <div className="col-sm-10">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deposit_item"
                      checked={newStudents.deposit_item === "1"}
                      onChange={handleInput}
                      value="1"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      普通
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deposit_item"
                      checked={
                        newStudents.type === "Regular"
                          ? newStudents.deposit_item === "2"
                          : false
                      }
                      onChange={handleInput}
                      value="2"
                      disabled={newStudents.type === "Short Term" || readOnly}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      当座
                    </label>
                  </div>

                  {!excludedUserIds.includes(userId) && (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="deposit_item"
                          value="納税基準"
                          onChange={handleInput}
                          checked={
                            newStudents.type === "Regular"
                              ? newStudents.deposit_item === "納税基準"
                              : false
                          }
                          disabled={
                            newStudents.type === "Short Term" || readOnly
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          納税基準
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  口座番号:<span className="text-white">-</span>
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={!readOnly ? "口座番号" : ""}
                    name="account_no"
                    onChange={handleInput}
                    value={
                      newStudents.type === "Regular"
                        ? Nvh({ value: newStudents.account_no })
                        : ""
                    }
                    disabled={newStudents.type === "Short Term" || readOnly}
                  />
                </div>

                <label className="col-sm-2 col-form-label text-sm-end">
                  口座名義人:
                </label>
                <div className="col-sm-4">
                  {readOnly ? (
                    <input
                      type="text"
                      className="form-control"
                      value={
                        newStudents.type === "Regular"
                          ? Nvh({ value: newStudents.account_holder })
                          : ""
                      }
                      disabled
                    />
                  ) : (
                    <input
                      className="form-control"
                      onChange={e =>
                        setNewStudents(p => ({
                          ...p,
                          account_holder: e.target.value,
                        }))
                      }
                      value={Nvh({ value: newStudents.account_holder })}
                      onBlur={() => {
                        setNewStudents(p => ({
                          ...p,
                          account_holder: convertToHalfWidthFunction(
                            newStudents.account_holder
                          ),
                        }));
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          e.target.blur(); // Blur the input field
                          setNewStudents(p => ({
                            ...p,
                            account_holder: convertToHalfWidthFunction(
                              newStudents.account_holder
                            ),
                          }));
                        }
                      }}
                    />
                    // <HalfWidthKatakana
                    //   className="form-control"
                    //   placeholder={!readOnly ? "口座名義人" : ""}
                    //   name="account_holder"
                    //   onChange={value =>
                    //     setNewStudents({
                    //       ...newStudents,
                    //       account_holder: value,
                    //     })
                    //   }
                    //   value={
                    //     newStudents.type === "Regular"
                    //       ? Nvh({ value: newStudents.account_holder })
                    //       : ""
                    //   }
                    //   isCreateMode={false} // Pass this prop to indicate it's not in create mode
                    //   disabled={newStudents.type === "Short Term" || readOnly}
                    // />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="d-flex">
        <div className="ml-auto">
          {loading ? (
            <>
              <ButtonLoader className="mr-2" />
              <ButtonLoader />
            </>
          ) : (
            <>
              {!readOnly ? (
                <>
                  <Button
                    variant="primary"
                    className="btn-block mt-4 mr-5 px-4"
                    type="submit"
                    onClick={() => updateFormSubmit(2)}
                  >
                    次へ [F8]
                  </Button>
                  <Button
                    variant="primary"
                    className="mx-auto btn-block mt-4"
                    type="submit"
                    onClick={() => updateFormSubmit(1)}
                  >
                    保存する [F9]
                  </Button>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    </span>
  );

  const studentInfoHeader = <StudentInfoHeader student={student} />;

  return (
    <>
      <RouteLeavingGuard
        when={whenState}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={location => {
          if (whenState) {
            return true;
          }
          return false;
        }}
        yes="yes"
        no="no"
        // content="Are you sure you want to leave?"
      />
      <div className="custom-scaling">
        <div className="card">
          <div className="card-header">
            <div className="d-flex align-items-center">
              {/* <h5>会員情報を更新します:</h5> */}

              <div className="">
                <span>
                  <button
                    onClick={() => {
                      setReadOnly(true);
                      handleUrlChange("view");
                    }}
                    className={`btn px-3 mr-4 ${
                      readOnly ? "btn-primary" : "btn-outline-primary"
                    }`}
                  >
                    参照モード [F2]
                  </button>
                </span>
                <span>
                  <button
                    onClick={() => {
                      setReadOnly(false);
                      handleUrlChange("update");
                    }}
                    className={`btn px-3 mr-4 ${
                      !readOnly ? "btn-primary" : "btn-outline-primary"
                    }`}
                  >
                    編集モード [F2]
                  </button>
                </span>
              </div>

              <h5 className="ml-5">会員情報を更新します:</h5>

              <div className="ml-auto">
                <Link
                  className="btn btn-sm btn-primary px-3 ml-auto"
                  variant="primary"
                  to="/admin/student"
                >
                  {" "}
                  <i className="fas fa-angle-left fa-fw"></i> 戻る
                </Link>
              </div>
            </div>
          </div>

          <div
            className="card-body"
            style={{ backgroundColor: cardBodyBackgroundColor }}
          >
            <div>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={k => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="tab1" title="個人情報 [Alt+X]">
                  {formSection}
                </Tab>
                <Tab
                  eventKey="tab2"
                  title="クラス [Alt+C]"
                  disabled={!enableTabs}
                >
                  <StudentClass
                    memberData={student}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                    setKey={setKey}
                  >
                    {studentInfoHeader}
                  </StudentClass>
                </Tab>
                <Tab
                  eventKey="tab3"
                  title="請求／入金 [Alt+A]"
                  disabled={!enableTabs}
                >
                  <Billing
                    studentInfo={student}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  >
                    {studentInfoHeader}
                  </Billing>
                </Tab>
                <Tab
                  eventKey="tab4"
                  title="出欠 [Alt+S]"
                  disabled={!enableTabs}
                >
                  <StudenPageAttendance
                    studentInfo={student}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  >
                    {studentInfoHeader}
                  </StudenPageAttendance>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateStudent;
