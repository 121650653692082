import "react-resizable/css/styles.css";
import StudentClassBody from "./StudentClassBody";
import StudentClassTable from "./StudentClassTable";
import StudentClassProvider from "src/provider/studentClassProvider";

const StudentClass = ({
  memberData,
  readOnly,
  setReadOnly,
  setKey,
  children,
}) => {
  return (
    <div class="container-fluid">
      <StudentClassProvider
        {...{
          memberData,
          readOnly,
          setReadOnly,
          isEdit: !readOnly,
          setKey,
        }}
      >
        {children}
        <StudentClassBody />
        <StudentClassTable />
      </StudentClassProvider>
    </div>
  );
};

export default StudentClass;
