import React, { useEffect, useState } from "react";
import "../../assets/common/css/modal.css";
import { Button, Table, Col, Row, Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import privateApi from "../../services/privateApi";
import BackButton from "../common/Button/BackButton";
import swal from "sweetalert";
import ja from "date-fns/locale/ja";
import { Skeleton } from "antd";
import { getCurrentMonth } from "../../services/auth/token";
import moment from "moment";
import { getMonth, getYear } from "date-fns";

const BillingProcess = () => {
  const font = {
    fontSize: "14px",
  };
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [banks, setBanks] = useState([]);

  console.log({ startDate });

  const [bankAccounts, setBankAccounts] = useState({
    bank_code: "",
    branch_code: "",
    deposit_item: "",
    account_number: "",
    bank_name_kana: "",
    bank_name: "",
    branch_name_kana: "",
    branch_name: "",
    account_name_kana: "",
    account_name: "",
    deposit_item_name: " ",
    transfer_flag: 1,
    consignor_code: "",
    consignor_name: "",
    file_name: null,
  });

  const refactorMonth = () => {
    let date = getCurrentMonth();

    let month = getCurrentMonth().getMonth();
    date.setMonth(month);
    setStartDate(new Date(date));
  };

  const getBankInfo = async id => {
    try {
      const response = await privateApi.get("admin/bank-informations/" + id);

      if (response.status === 200) {
        setBankAccounts(response.data.data);
      } else {
        swal("内容に問題があります", response.data.message, "error");
      }
    } catch (error) {
      swal("内容に問題があります", "error");
    }
  };

  const handleClick = index => {
    const selectedBank = banks[index];
    setSelectedIndex(index + 1);
    setSelectedBank(selectedBank.id);
    getBankInfo(selectedBank.id);
    setBankAccounts(selectedBank);
  };

  const getAllBanks = async pageNumber => {
    setLoading(true);
    try {
      const response = await privateApi.get(
        `admin/bank-informations?page=${pageNumber}`
      );

      if (response.status === 200) {
        setBanks(response.data.data);
        setPaginationBtns(response.data?.meta);
      } else {
        swal("内容に問題があります", response.data.message, "error");
      }
    } catch (error) {
      swal("内容に問題があります", "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllBanks();
    refactorMonth();
  }, []);

  const handleInput = e => {
    e.persist();
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const daySelectionFlagValue = checked ? 1 : 0;
      setBankAccounts({ ...bankAccounts, [name]: daySelectionFlagValue });
    } else {
      setBankAccounts({ ...bankAccounts, [name]: value });
    }
  };

  const handleDownloadTextFile = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const requestData = {
        id: selectedBank,
        start_date: moment(startDate).format("YYYY-MM-DD"),
      };

      const response = await privateApi.post(
        "admin/bank-csv-export",
        requestData
      );

      if (response.status === 200) {
        const selectedBankName =
          banks.find(bank => bank.id === selectedBank)?.bank_name || "unknown";
        const fileName = `${selectedBankName}_${moment(startDate).format(
          "YYYY-MM-DD"
        )}.txt`;

        privateApi.handleClickRedirect("admin/download/bank-csv", {
          filePath: response.data.filePath,
          fileName: fileName,
        });
      } else {
        swal("エラー", response.data.message, "error");
      }
    } catch (error) {
      swal("エラー", "ダウンロード中にエラーが発生しました", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="card">
        <div className="card-body mt-0 mx-1">
          <div className="table-responsive">
            {loading ? (
              <Skeleton paragraph={{ rows: 3 }} />
            ) : (
              <Table
                striped
                bordered
                hover
                responsive="xl"
                className="w-100 text-left"
              >
                <thead>
                  <tr>
                    <th className="text-center" style={{ minWidth: "50px" }}>
                      番号
                    </th>
                    <th className="text-center" style={{ minWidth: "100px" }}>
                      銀行コード
                    </th>
                    <th className="text-center" style={{ minWidth: "100px" }}>
                      支店コード
                    </th>
                    <th className="text-center" style={{ minWidth: "100px" }}>
                      預金種目
                    </th>
                    <th className="text-center" style={{ minWidth: "100px" }}>
                      口座番号
                    </th>
                    <th className="text-center" style={{ minWidth: "100px" }}>
                      銀行名カナ
                    </th>
                    <th className="text-center" style={{ minWidth: "150px" }}>
                      銀行名
                    </th>
                    <th className="text-center" style={{ minWidth: "100px" }}>
                      支店名カナ
                    </th>
                    <th className="text-center" style={{ minWidth: "100px" }}>
                      支店名
                    </th>
                    <th className="text-center" style={{ minWidth: "170px" }}>
                      口座名カナ
                    </th>
                    <th className="text-center" style={{ minWidth: "170px" }}>
                      口座名
                    </th>
                    <th className="text-center" style={{ minWidth: "100px" }}>
                      預金種目名
                    </th>
                    <th className="text-center" style={{ minWidth: "100px" }}>
                      振替フラグ
                    </th>
                    <th className="text-center" style={{ minWidth: "120px" }}>
                      委託者コード
                    </th>
                    <th className="text-center" style={{ minWidth: "170px" }}>
                      受託者名
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {banks.map((bank, index) => (
                    <tr
                      onClick={() => handleClick(index)}
                      key={index}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedBank === bank.id ? "#eaf6ff" : "",
                      }}
                    >
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{bank.bank_code}</td>
                      <td className="text-center">{bank.branch_code}</td>
                      <td className="text-center">{bank.deposit_item}</td>
                      <td className="text-center">{bank.account_number}</td>
                      <td className="text-center">{bank.bank_name_kana}</td>
                      <td className="text-center">{bank.bank_name}</td>
                      <td className="text-center">{bank.branch_name_kana}</td>
                      <td className="text-center">{bank.branch_name}</td>
                      <td className="text-center">{bank.account_name_kana}</td>
                      <td className="text-center">{bank.account_name}</td>
                      <td className="text-center">{bank.deposit_item_name}</td>
                      <td className="text-center ">
                        <Form.Check
                          className="d-flex justify-content-center  mx-2"
                          type="checkbox"
                          id={`custom-switch-${index}`}
                          label=""
                          checked={bank.transfer_flag}
                          onChange={() => {}}
                        />
                      </td>
                      <td className="text-center">{bank.consignor_code}</td>
                      <td className="text-center">{bank.consignor_name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>

      <div className="card col-sm-10 col-md-8 col-lg-9 col-xl-9 mt-1">
        <div className="card-header p-0 m-0">
          <h5 className="card-title m-1">銀行情報</h5>
        </div>
        <div className="card-body mt-1">
          <div className="container">
            <Form>
              <div className="row">
                <div className="col-md-10">
                  <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={{ font }} column sm="2">
                      処理件数
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        size="sm"
                        value={paginationBtns.total}
                        onChange={handleInput}
                        name="bank_code"
                      />
                    </Col>
                    <Col sm="1" style={{ width: "9%" }}>
                      <p style={font}>件中</p>
                    </Col>
                    <Col sm="3">
                      <Form.Control
                        size="sm"
                        value={selectedIndex !== null ? selectedIndex : ""}
                        onChange={handleInput}
                        name="bank_name"
                      />
                    </Col>
                    <Col sm="3" style={{ width: "20%" }}>
                      <p style={font}>件目の処理</p>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={font} column sm="2">
                      取引銀行:
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.bank_code}
                        onChange={handleInput}
                        name="bank_code"
                      />
                    </Col>
                    <Col sm="5">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.bank_name}
                        onChange={handleInput}
                        name="bank_name"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={font} column sm="2">
                      取引支店::
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.branch_code}
                        onChange={handleInput}
                        name="branch_code"
                      />
                    </Col>
                    <Col sm="5">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.branch_name}
                        onChange={handleInput}
                        name="branch_name"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={font} column sm="2">
                      預金種目
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.deposit_item}
                        onChange={handleInput}
                      />
                    </Col>
                    <Col sm="5">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.deposit_item_name}
                        onChange={handleInput}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={font} column sm="2">
                      口座番号
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.account_number}
                        onChange={handleInput}
                      />
                    </Col>
                    <Col sm="5">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.account_name_kana}
                        onChange={handleInput}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={font} column sm="2">
                      受託者名
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.consignor_name}
                        onChange={handleInput}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={font} column sm="2">
                      受託者コード
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        size="sm"
                        value={bankAccounts.consignor_code}
                        onChange={handleInput}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={font} column sm="2">
                      引落日:
                    </Form.Label>
                    <Col sm="4">
                      <ReactDatePicker
                        className="form-control form-control-sm"
                        dateFormat="yyyy/MM/dd"
                        selected={startDate}
                        locale={ja}
                        onChange={date => {
                          if (date) {
                            setStartDate(date);
                          }
                        }}
                        filterDate={date => {
                          // Chỉ cho phép chọn ngày trong cùng tháng với startDate
                          return (
                            getMonth(date) === getMonth(startDate) &&
                            getYear(date) === getYear(startDate)
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                  {/* <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={font} column sm="2">
                      出力先:
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control size="sm" value={"Downloads"} readOnly />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className=""
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label style={font} column sm="2">
                      ファイル名:
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control size="sm" value={"UFJ"} readOnly />
                    </Col>
                  </Form.Group> */}
                </div>
                <div className="col-md-2">
                  <div className="row">
                    <Button
                      size="sm"
                      className=""
                      variant="primary"
                      type="submit"
                      style={{ width: "110px" }}
                      onClick={handleDownloadTextFile}
                    >
                      実行
                    </Button>
                  </div>
                  <div className="row">
                    {" "}
                    <Button
                      size="sm"
                      className="my-2"
                      variant="primary"
                      type="submit"
                      style={{ width: "110px" }}
                    >
                      スキップ
                    </Button>
                  </div>
                  <div className="row">
                    <Button
                      size="sm"
                      className="mb-2"
                      variant="primary"
                      type="button"
                      style={{ width: "110px" }}
                    >
                      キャンセル
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <BackButton redirectTo="/admin/bank/dashboard" />
    </div>
  );
};

export default BillingProcess;
