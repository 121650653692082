import { Formik } from "formik";
import { useContext } from "react";
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { swalWarning } from "src/utils/swalHelpers";
import messages from "src/utils/messages";
import TextLabel from "./TextLabel";
import useStudentClass from "src/hooks/useStudentClass";

function StudentClassFormTabs({ disableButtons }) {
  const {
    updateFormList,
    formList,
    setSelectedClassId,
    billingAmount,
    setBillingAmount,
    selectedClassId,
    regularClassList,
    totalFees,
    maxAllowedClasses,
    validateFormData,
  } = useStudentClass();

  const getAffiliationName = regular_class_id => {
    return regularClassList?.find(val => val.id === regular_class_id)
      ?.affiliation_name;
  };

  const handleClassToggle = async id => {
    if (!(await validateFormData())) {
      return null;
    }

    setSelectedClassId(id);
  };

  const handleAddClass = async () => {
    if (!(await validateFormData())) {
      return null;
    }

    if (formList[formList.length - 1].regular_class_id) {
      updateFormList(null, "add");
    } else {
      swalWarning(messages.addClassWarning);
    }
  };

  // Render buttons for class data
  const renderClassButtons = () => {
    return formList.map(item => {
      const buttonClassName =
        "mb-0 border btn shadow-md" +
        (selectedClassId === item?.id ? " btn-secondary" : "");

      return (
        <button
          key={item.id}
          className={buttonClassName}
          type="button"
          onClick={() => handleClassToggle(item.id)}
        >
          {getAffiliationName(item?.regular_class_id) || "--"}
        </button>
      );
    });
  };

  const handleBillingAmountUpdate = e => {
    setBillingAmount(e.target.value);
  };

  return (
    <Formik
      initialValues={{ fees_total: 0, billing_amount: 0 }}
      enableReinitialize={true}
    >
      {formik => (
        <Form>
          <div className="row m-0 mt-2 justify-content-between student-class-form-tabs">
            <div className="col-md-12">
              <div className="row m-0 g-1 align-items-center">
                {/* Class Tabs */}
                <div className="col-md-2 class-tabs">
                  {renderClassButtons()}
                  {formList.length < maxAllowedClasses && (
                    <button
                      className="mb-0 border btn shadow-md"
                      type="button"
                      onClick={handleAddClass}
                      disabled={disableButtons}
                    >
                      +
                    </button>
                  )}
                </div>

                {/* Spacer */}
                <div className="col-md-7"></div>

                {/* Fees and Billing */}
                <div className="col-md-1 text-end">授業料合計:</div>
                <div className="col-md-1 px-2">
                  <TextLabel label={totalFees} />
                </div>
                <div className="col-md-1 px-2">
                  <input
                    className="form-control"
                    value={billingAmount}
                    onChange={handleBillingAmountUpdate}
                  />
                  <div className="feeback px-1 invalid-feedback"></div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default StudentClassFormTabs;
