import { Form } from "react-bootstrap";

// this is a non formik component
function MyBsSelect({ inputRef, label, children, ...props }) {
  const allProps = { ...props };

  const labelClass = !!label ? "" : "d-none";

  return (
    <Form.Group className="my-input my-select" controlId={props.name}>
      <Form.Label className={labelClass}>{label}</Form.Label>
      <Form.Select ref={inputRef} {...allProps}>
        {children}
      </Form.Select>
      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );
}

export default MyBsSelect;
