import { useState } from "react";
import AppContext from "src/context/appContext";
import { getSchool } from "src/services/auth/token";

export const AppProvider = ({ children }) => {
  const [schoolSlug, setSchoolSlug] = useState(
    getSchool() ? "/" + getSchool() : ""
  );

  const schoolSlugWithoutPath = getSchool();

  const contextValues = { schoolSlug, setSchoolSlug, schoolSlugWithoutPath };

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
