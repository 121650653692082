import "react-resizable/css/styles.css";
import BillingBody from "src/components/extrapage/Page19/BillingBody";
import BillingTable from "src/components/extrapage/Page19/BillingTable";
import _ from "lodash";
import useNav from "src/hooks/useNav";
import { useCallback, useEffect, useRef, useState } from "react";

const Billing = ({ studentInfo, readOnly, setReadOnly, children }) => {
  const { currentMonth } = useNav();

  const tableFilterButtonRef = useRef(null);

  const [depositData, setDepositData] = useState({});
  const [depositList, setDepositList] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);

  // note praveen keep this in sync with other states!
  const [checked, setChecked] = useState(false);
  const [date, setDate] = useState(currentMonth);

  const updateState = useCallback(depositData => {
    setDepositData(depositData);
    setChecked(depositData.billing_check);
    setDate(depositData.processed_date);
    setSelectedRowId(depositData.id);
  }, []);

  const updateDepositList = useCallback(data => {
    setDepositList(data);
  }, []);

  useEffect(() => {
    if (currentMonth) {
      setDate(currentMonth);
    }
  }, [currentMonth]);

  const handleCheckedChange = e => {
    setChecked(e.target.checked ? 1 : 0);
  };

  return (
    <div class="container-fluid">
      {children}
      <BillingBody
        studentInfo={studentInfo}
        readOnly={readOnly || _.isEmpty(studentInfo) || !currentMonth} // note praveen move this logic so that it permeates throughout the page
        setReadOnly={setReadOnly}
        isEdit={!readOnly}
        depositData={depositData}
        checked={checked}
        date={date}
        updateState={updateState}
        onCheckedChange={handleCheckedChange}
        tableFilterButtonRef={tableFilterButtonRef}
      />
      <BillingTable
        studentInfo={studentInfo}
        updateState={updateState}
        tableFilterButtonRef={tableFilterButtonRef}
        depositList={depositList}
        updateDepositList={updateDepositList}
        depositData={depositData}
        selectedRowId={selectedRowId}
      />
    </div>
  );
};

export default Billing;
