import { Table as AntTable, Checkbox, Input } from "antd";
import "react-resizable/css/styles.css";
import { ConfigProvider } from "antd";
import antdTheme from "src/themes/antdTheme";
import RescheduledDayInput from "src/components/admin/student/RescheduledDayInput.js";
import { dateFormat } from "src/utils/formatdate";

// update props
const AttendanceTable = ({
  tableData,
  handleUpdate,
  readOnly,
  rescehdulableDatesList,
}) => {
  const psuedoInputEvent = (property, value, name) => {
    return {
      target: {
        [property]: value,
        name,
      },
    };
  };

  const columns = [
    {
      title: "出欠枠",
      dataIndex: "attendanceBlock",
      key: "attendanceBlock",
      width: 150,
      render: attendanceBlock => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{attendanceBlock[0] && `${attendanceBlock[0]} 週間目`}</div>
          <div>#{attendanceBlock[1]}</div>
        </div>
      ),
      // sorter: (a, b) => normalSort(a.sortIndex, b.sortIndex),
    },
    {
      title: "日時",
      dataIndex: "attendance_date",
      key: "attendance_date",
      width: 240,
      render: (
        _,
        { attendance_date: date, start_time: time, present, isHoliday }
      ) => {
        return (
          date &&
          time && (
            <div>
              <span>{dateFormat(`${date} ${time}`, 1)}</span>
              <span>{isHoliday ? " (休欠)" : !present && " (欠)"}</span>
            </div>
          )
        );
      },
      // sorter: (a, b) => dateSort(a.datetime, b.datetime),
    },
    {
      title: "出席",
      dataIndex: "present",
      key: "present",
      width: 70,
      render: (present, row, index) => (
        <Checkbox
          type="checkbox"
          name="present"
          onClick={e => handleUpdate(e, index, "checked")}
          checked={!!present}
          disabled={readOnly || row.rescheduled}
        />
      ),
      // sorter: (a, b) => booleanSort(!!a.present, !!b.present),
    },
    {
      title: "振替",
      dataIndex: "rescheduled",
      key: "rescheduled",
      render: (rescheduled, row, index) => {
        // Check if the row's attendance_date exists in the holidayList
        let canReschedule = row.isHoliday;

        if (!canReschedule) {
          // If the row cannot be rescheduled based on the holidayList,
          // check if all rows in the tableData that are not in the holidayList
          // have a rescheduled value of 0
          const nonHolidayRows = tableData.filter(
            rowData => !rowData.isHoliday
          );

          canReschedule = nonHolidayRows.every(
            rowData => rowData.rescheduled === 0
          );
        }

        const disabled = row.present || (!canReschedule && !rescheduled);
        // i know this is hard to walkthrough but bear with me
        // the checkbox should be disabled if and only if
        // the canRescedule is false (meaning cannot reschedule) and current rows rescehdule is also false (current row is not checked true)
        // since it would be silly to not let the user uncheck if he wanted to

        return (
          <Checkbox
            type="checkbox"
            name="rescheduled"
            disabled={disabled || readOnly}
            onClick={e => {
              handleUpdate(e, index, "checked");
              if (!e.target.checked) {
                // Reset other related fields when the checkbox is unchecked
                handleUpdate([
                  [e, index, "checked"],
                  [
                    psuedoInputEvent("checked", false, "reschedule_present"),
                    index,
                    "checked",
                  ],
                  [
                    psuedoInputEvent(
                      "value",
                      null,
                      "reschedule_regular_class_schedule"
                    ),
                    index,
                    "value",
                  ],
                  [
                    psuedoInputEvent("value", null, "reschedule_date"),
                    index,
                    "value",
                  ],
                ]);
              }
            }}
            checked={!!rescheduled}
          />
        );
      },
      width: 70,
      // sorter: (a, b) => booleanSort(!!a.rescheduled, !!b.rescheduled),
    },
    {
      title: "振替日",
      dataIndex: "reschedule_regular_class_schedule",
      key: "reschedule_regular_class_schedule",
      width: 220,
      render: (reschedule_regular_class_schedule, row, index) => {
        const rowResDates = rescehdulableDatesList[row.id];

        const options = rowResDates
          ? rowResDates
              .filter(dates => dates.allow > 0)
              .map(dates => {
                return {
                  label: `${dates.date} ${dates.start_time}`,
                  value: `${dates.date} ${dates.start_time}`,
                  reschedule_regular_class_schedule: dates.schedule_id,
                  date: dates.date,
                };
              })
          : [];

        const value = options.find(val => {
          return (
            val.reschedule_regular_class_schedule ===
              row.reschedule_regular_class_schedule &&
            val.date === row.reschedule_date
          );
        });

        return (
          <RescheduledDayInput
            options={options}
            value={value}
            disabled={!row.rescheduled || readOnly}
            onChange={value => {
              const resDate = rowResDates.find(
                val => `${val.date} ${val.start_time}` === value
              );

              handleUpdate([
                [
                  {
                    target: {
                      value: resDate.schedule_id,
                      name: "reschedule_regular_class_schedule",
                    },
                  },
                  index,
                  "value",
                ],
                [
                  {
                    target: {
                      value: resDate.date,
                      name: "reschedule_date",
                    },
                  },
                  index,
                  "value",
                ],
              ]);
            }}
          />
        );
      },
      // sorter: (a, b) => dateSort(a.rescheduledDay, b.rescheduledDay),
    },
    {
      title: "振出",
      dataIndex: "reschedule_present",
      key: "reschedule_present",
      render: (reschedule_present, row, index) => {
        const disabled = !row.rescheduled;
        return (
          <Checkbox
            type="checkbox"
            name="reschedule_present"
            disabled={disabled || readOnly}
            onClick={e => handleUpdate(e, index, "checked")}
            checked={!!reschedule_present}
          />
        );
      },
      width: 70,
      // sorter: (a, b) => booleanSort(!!a.rescheduled, !!b.rescheduled),
    },
    {
      title: "備考",
      dataIndex: "comment",
      key: "comment",
      render: (comment, row, index) => (
        <Input
          type="text"
          name="comment"
          onChange={e => handleUpdate(e, index, "value")}
          value={comment}
          disabled={readOnly}
        />
      ),
      // sorter: (a, b) => stringSort(a.comment, b.comment),
    },
  ];

  return (
    <div className="row" style={{ fontSize: "20px !important" }}>
      <div className="table-responsive student-attendance-table">
        <ConfigProvider theme={antdTheme}>
          <AntTable
            bordered
            dataSource={tableData}
            columns={columns}
            className="mt-4 text-left"
            pagination={false}
            rowClassName={record => record.isEmpty && "disabled-row"}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default AttendanceTable;
