import { Form } from "react-bootstrap";

function MyBsCheckbox({ inputRef, ...props }) {
  return (
    <Form.Group>
      <Form.Check ref={inputRef} type="checkbox" {...props} />
      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
    </Form.Group>
  );
}

export default MyBsCheckbox;
