import "react-resizable/css/styles.css";
import { useEffect, useState, useCallback } from "react";
import useNav from "src/hooks/useNav";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import MyInput from "src/components/common/FormElements/MyInput";
import BillingForm from "src/components/extrapage/Page19/BillingForm.js";
import {
  createDeposit,
  searchDeposit,
  updateDeposit,
} from "src/services/privateApi";
import _ from "lodash";
import swal from "sweetalert";

const BillingBody = ({
  studentInfo,
  readOnly,
  setReadOnly,
  isEdit,
  depositData,
  checked,
  date,
  updateState,
  onCheckedChange,
  tableFilterButtonRef,
}) => {
  const { currentMonth } = useNav();
  const [isSearching, setIsSearching] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const getSearchAddPayload = useCallback(
    date => {
      setIsSearching(true);

      const payload = {
        member_id: studentInfo.id,
        date,
      };

      return payload;
    },
    [studentInfo.id]
  );

  const depositAdd = useCallback(
    async date => {
      setIsSearching(true);

      const payload = getSearchAddPayload(date);

      try {
        const { data, err } = await createDeposit(payload);

        if (err) {
          if (data.message) swal(data.message, "", "warning");
          throw new Error(data.message);
        } else {
          updateState(data.data);
          tableFilterButtonRef.current.click();
        }
      } catch (err) {
        console.log(err);
      }

      setIsSearching(false);
    },
    [getSearchAddPayload, updateState, tableFilterButtonRef]
  );

  const depositSearch = useCallback(
    async (date, searchType, isAlert = true) => {
      setIsSearching(true);

      const payload = getSearchAddPayload(date);

      try {
        const { data, err } = await searchDeposit(payload);

        if (err) {
          if (data.message) swal(data.message, "", "warning");
          throw new Error(data.message);
        }

        if (data?.data && Array.isArray(data?.data) && !data?.data?.length) {
          if (searchType === "add") {
            return depositAdd(date);
          } else if (searchType === "read") {
            isAlert && swal("選択年月には請求入金データがありません", "", "warning");
          }
          throw new Error("No data found");
        } else {
          updateState(data.data);
        }
      } catch (err) {
        console.log(err);
      }

      setIsSearching(false);
    },
    [depositAdd, getSearchAddPayload, updateState]
  );

  const depositUpdate = useCallback(
    async values => {
      setIsSearching(true);

      const id = depositData.id;
      const payload = { ...values, billing_check: checked };

      try {
        const { data, err } = await updateDeposit(payload, id);

        if (err) {
          if (data.message) swal(data.message, "", "warning");
          throw new Error(data.message);
        } else {
          // note praveen left the code for completion
          // updateState(data.data, data.data.processed_date);
          updateState(data.data);
          setReadOnly(true);
          tableFilterButtonRef.current.click();
        }
      } catch (err) {
        console.log(err);
      }

      setIsSearching(false);
    },
    [depositData.id, checked, setReadOnly, updateState, tableFilterButtonRef]
  );

  useEffect(() => {
    if (studentInfo.id && currentMonth) {
      depositSearch(currentMonth, "read", false);
      setHasLoaded(true);
    }
  }, [currentMonth, studentInfo.id, depositSearch]);

  return (
    <div className="row m-0 ">
      <div className="card pb-1 pt-2">
        <div className="row m-0 mt-2 my-2">
          <Formik
            initialValues={{ date }}
            enableReinitialize={true}
            onSubmit={async ({ date }, { setSubmitting }) => {
              setSubmitting(true);

              const searchType = isEdit ? "add" : "read";

              await depositSearch(date, searchType);

              setSubmitting(false);
            }}
          >
            {formik => {
              const buttonProps = isEdit
                ? { children: "検索/追加" }
                : { children: "検索" };

              const disabled =
                _.isEmpty(studentInfo) ||
                !currentMonth ||
                formik.isSubmitting ||
                isSearching ||
                !hasLoaded;

              return (
                <div className="row col-md-5">
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-3 text-md-end pt-1">年月:</div>
                      <div className="col-4">
                        <MyInput
                          label=""
                          name="date"
                          type="month"
                          placeholder=""
                          disabled={disabled}
                        />
                      </div>
                      <div className="col-md-3">
                        <button
                          className="btn btn-primary w-100"
                          type="submit"
                          disabled={disabled}
                          {...buttonProps}
                        ></button>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
          {/* note praveen if possible, take this inside the formik form later*/}
          <div className="col-md-5"></div>
          <div className="col-md-2">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={checked}
                disabled={readOnly || isSearching}
                depositUpdate={depositUpdate} // note praveen this is ugly!! and repeated
                onChange={onCheckedChange}
              />
              <label className="form-check-label">
                <b>チェック</b>
              </label>
            </div>
          </div>
        </div>
        <BillingForm
          depositData={depositData}
          studentInfo={studentInfo}
          date={date}
          readOnly={readOnly || isSearching || !hasLoaded} // note praveen this is ugly!! and repeated
          depositUpdate={depositUpdate}
        />
      </div>
    </div>
  );
};

export default BillingBody;
