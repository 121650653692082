import * as Yup from "yup";

const billingFormValidationSchema = Yup.object({
  billing_amount: Yup.number()
    .min(0, "0か0以上の数字を入力してください")
    .required("*必須"),
  cash_deposit_amount: Yup.number()
    .nullable() // Allow it to be null initially
    .test(
      "required-with-date",
      "入金額を入力するか入金日を削除してください",
      function (value) {
        const { cash_deposit_date } = this.parent;
        return !cash_deposit_date || typeof value === "number"; // If date exists, amount must be > 0
      }
    ),
  cash_deposit_date: Yup.date()
    .nullable() // Allow it to be null initially
    .test(
      "required-with-amount",
      "入金日を入力するか入金額を削除してください",
      function (value) {
        const { cash_deposit_amount } = this.parent;
        return typeof cash_deposit_amount !== "number" || !!value; // If amount exists, date must not be null
      }
    ),
  cash_deposit_amount2: Yup.number()
    .nullable()
    .test(
      "required-with-date",
      "入金額2を入力するか入金日2を削除してください",
      function (value) {
        const { cash_deposit_date2 } = this.parent;
        return !cash_deposit_date2 || typeof value === "number";
      }
    ),
  cash_deposit_date2: Yup.date()
    .nullable()
    .test(
      "required-with-amount",
      "入金日2を入力するか入金額2を削除してください",
      function (value) {
        const { cash_deposit_amount2 } = this.parent;
        return typeof cash_deposit_amount2 !== "number" || !!value;
      }
    ),
});

export default billingFormValidationSchema;
