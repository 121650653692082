import { Button, Table } from "react-bootstrap";
import TextLabel from "src/components/extrapage/Page19/TextLabel.js";
import { memberClassAssign, memberClassDelete } from "src/services/privateApi";
import messages from "src/utils/messages";
import { swalConfirm, swalError, swalSuccess } from "src/utils/swalHelpers";
import MyBsSelect from "src/components/common/FormElements/MyBsSelect";
import createArrayFromSize from "src/utils/creatArrayFromSize";
import MyBsInput from "src/components/common/FormElements/MyBsInput";
import MyBsCheckbox from "src/components/common/FormElements/MyBsCheckbox";
import StudentClassFormTabs from "./StudentClassFormTabs";
import useStudentClass from "src/hooks/useStudentClass";
import generateClassFormData from "src/utils/generateClassFormData";
import MonthLabel from "./MonthLabel";
import deepCopy from "src/utils/deepCopy";
import { useState } from "react";
import { rest } from "lodash";

const StudentClassForm = () => {
  const {
    regularClassList,
    tuitonFeesList,
    regularClassScheduleList,
    classLevelList,
    billingAmount,
    date,
    setReadOnly,
    memberData,
    formList,
    updateFormList,
    formData,
    filterByRank,
    validateFormData,
    fetchMemberClassesDataWrapper,
    formikRef,
  } = useStudentClass();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getClassSchedule = regular_class_id => {
    let classSchedule = regularClassScheduleList.find(
      val => parseInt(val.regular_class_id) === parseInt(regular_class_id)
    )?.classSchedule;

    if (!classSchedule) return null;

    classSchedule = classSchedule
      .filter(val => val.classes.length)
      .map(({ day, classes }) => {
        return {
          day,
          classes: [
            ...classes
              .filter(ele => ele.start_time)
              .map(ele => {
                const classLevelInfo = classLevelList.find(
                  ({ id }) => id === ele.max_student
                );
                const timeOptionString = classLevelInfo
                  ? `${ele.start_time} (${classLevelInfo?.in_text})`
                  : `${ele.start_time} (--)`;

                return { ...ele, day, timeOptionString, classLevelInfo };
              }),
          ],
        };
      });

    return classSchedule;
  };

  const classSchedule = getClassSchedule(formData.regular_class_id);

  const affiliationOptionList = () => {
    return [
      { id: "no-value", affiliation_name: "クラスを選んでくださいください" },
      ...regularClassList,
    ]
      .filter(val => {
        return !formList
          .filter(val => val.id !== formData.id)
          .map(val => val.regular_class_id)
          .includes(val.id);
      })
      .map(val => {
        return {
          ...val,
          value: val.id,
          label: val.affiliation_name,
        };
      });
  };

  const timesOptionList = (regular_class_id = formData.regular_class_id) => {
    return tuitonFeesList
      .filter(val => val.regular_class_id === regular_class_id)
      .map(val => {
        return { ...val, value: val.times, label: val.times };
      });
  };

  const daysOptionList = () => {
    return [
      { value: "no-value", label: "曜日を選択してください" },
      ...(classSchedule?.map(val => ({
        ...val,
        value: val.day,
        label: val.day,
      })) || []),
    ];
  };

  const dayTimeOptionList = pos => {
    const dayWeek = formData[`day_week_${pos}`];

    const options = [{ value: "no-value", label: "時間を選択してください" }];

    if (classSchedule && dayWeek) {
      const weekDaySchedule = classSchedule.find(val => val.day === dayWeek);

      weekDaySchedule.classes.forEach(val => {
        if (
          !filterByRank() ||
          (formData.class &&
            formData.class >= val?.classLevelInfo?.from_level &&
            formData.class <= val?.classLevelInfo?.to_level)
        ) {
          options.push({ ...val, value: val.id, label: val.timeOptionString });
        }
      });
    }

    return options;
  };

  const generateOptions = list => {
    return list.map(val => {
      return (
        <option key={val.value} value={val.value}>
          {val.label}
        </option>
      );
    });
  };

  const handleInputChange = (e, customLogic = {}) => {
    const { name, value, checked } = e.target;
    const isCheckbox = e.target.type === "checkbox";

    const newFormData = {
      ...formData,
      [name]: isCheckbox ? (checked ? 1 : 0) : value,
      ...customLogic,
    };

    updateFormList(newFormData);
  };

  const handleAffiliationChange = e => {
    const { value } = e.target;

    const newFormData = generateClassFormData();
    newFormData.id = formData.id;
    newFormData.closed_flag = formData.closed_flag;
    newFormData.regular_class_id =
      value === "no-value" ? null : parseInt(value);
    newFormData.times = timesOptionList(newFormData.regular_class_id)[0]?.value;

    console.log(newFormData);

    updateFormList(newFormData);
  };

  const handleTimesChange = e => {
    const { name, value } = e.target;
    const newFormData = deepCopy(formData);

    createArrayFromSize(5)
      .filter(val => val > parseInt(value))
      .forEach(pos => {
        [
          `day_week_${pos}`,
          `start_time_${pos}`,
          `end_time_${pos}`,
          `class_level_${pos}`,
          `bus_flag_${pos}`,
        ].forEach(key => {
          newFormData[key] = key.includes("bus_flag") ? 0 : null;
        });
      });

    newFormData[name] = parseInt(value);
    updateFormList(newFormData);
  };

  // Using the generalized handler for similar cases
  const handleClassChange = e => {
    handleInputChange(e, { specialStudy: e.target.value === "0" ? 1 : 0 });
  };

  const handleSpecialCheckboxChange = e => {
    handleInputChange(e, { class: e.target.checked ? "0" : "" });
  };

  const handleTableSelectChange = e => {
    handleInputChange(e, {
      [e.target.name]: e.target.value === "no-value" ? null : e.target.value,
    });
  };

  const handleWeekdayChange = handleTableSelectChange;

  const handleTimeChange = handleTableSelectChange;

  const handleKyuukouChange = handleInputChange;

  const handleBusChange = handleInputChange;

  const getClassFees = () => {
    const { closed_flag, times, regular_class_id } = formData;

    const tuition = tuitonFeesList.find(
      fee => fee.regular_class_id === regular_class_id && fee.times === times
    );

    return tuition
      ? closed_flag
        ? tuition.closure_fee
        : tuition.tution_fee
      : "";
  };

  const renderRow = (label, renderCellContent, customClassName = "") => {
    const tdClassName = customClassName || "text-center";

    return (
      <tr>
        <th
          className="text-center"
          style={{ width: "145px", whiteSpace: "nowrap" }}
        >
          {label}
        </th>
        {createArrayFromSize(5).map(val => (
          <td key={val} className={tdClassName}>
            {renderCellContent(val)}
          </td>
        ))}
      </tr>
    );
  };

  const handleDelete = async () => {
    const shouldDelete = await swalConfirm(messages.classDataDeleteConfirm);

    if (shouldDelete) {
      if (!formData.id.toString().includes("_generated_data")) {
        setIsSubmitting(true);
        const payload = {
          class_id: formData.id,
          member_id: memberData.id,
          processed_date: date,
          regular_class_id: formData.regular_class_id,
        };

        const { resType, data } = await memberClassDelete(payload);
        if (resType === 1) {
          fetchMemberClassesDataWrapper(memberData.id, "effect");
          updateFormList(formData, "delete");
          await swalSuccess(messages.classDataUpdate);
        }
        if (resType === 3) await swalError(messages.contactMessage);
      } else {
        fetchMemberClassesDataWrapper(memberData.id, "effect");
        updateFormList(formData, "delete");
      }

      setIsSubmitting(false);
      setReadOnly(true);
    }
  };

  // note praveen, a similar function exists in RankHistory
  const handleSave = async () => {
    if (!(await validateFormData())) {
      return null;
    }

    setIsSubmitting(true);

    const result = await Promise.all(
      formList.map(async (formData, i) => {
        const payload = {
          weekdays: [],
          class_times: [],
          buses: [],
          member_id: memberData.id,
          month: date,
          class_id: formData.regular_class_id,
          times: formData.times,
          class_level: formData.class,
          billing_amount: billingAmount,
          closed_flag: formData.closed_flag,
          id: formData.id,
        };

        [1, 2, 3, 4, 5].forEach(val => {
          const day = formData[`day_week_${val}`];
          const class_level = formData[`class_level_${val}`] || "";

          const { start_time, end_time } =
            classSchedule
              ?.find(schedule => day === schedule.day)
              ?.classes?.find(cls => cls.id === parseInt(class_level)) || {};

          payload.weekdays.push(formData[`day_week_${val}`] || "");
          payload.buses.push(formData[`bus_flag_${val}`]);
          payload.class_times.push({
            start_time: start_time || "",
            end_time: end_time || "",
            class_level,
          });
        });

        return memberClassAssign(payload);
      })
    );

    const isSuccess = result.every(val => val.resType === 1);
    if (isSuccess) {
      fetchMemberClassesDataWrapper(memberData.id, "effect");
      await swalSuccess(messages.classDataUpdate);
    } else {
      const isTypeThree = result.some(val => val.resType === 3);
      if (isTypeThree) await swalError(messages.contactMessage);
    }

    setReadOnly(true);
    setIsSubmitting(false);
  };

  const { disableForm } = useStudentClass();

  const disableNonAffiliationWidgets =
    disableForm || !formData.regular_class_id || isSubmitting;

  const disableRankInputs = disableForm || !filterByRank() || isSubmitting;

  return (
    <div
      key={formData.id}
      className="row m-0 my-1 p-1 student-class-form"
    >
      <div className="border border-1">
        <div className="row m-0 mt-2 justify-content-between ">
          <div className="col-md-12">
            <div className="row m-0 g-1 align-items-center">
              <div className="col-md-1">処理年月:</div>
              <div className="col-md-1">
                <MonthLabel date={date} />
              </div>
              <div className="col-md-9"></div>
              <div className="col-md-1 px-2 billing-amount-label">
                <TextLabel label="請求額" />
              </div>
            </div>
          </div>
        </div>
        <StudentClassFormTabs disableButtons={disableNonAffiliationWidgets} />
        <div className="mb-2">
          <div className="col-12 mb-2 border px-2 py-1">
            <div className="col-md-12">
              <div className="row m-0 g-1 align-items-center">
                <div className="col-md-1 text-end">所属:</div>
                <div className="col-md-2 px-2">
                  <MyBsSelect
                    label=""
                    name="regular_class_id"
                    value={formData.regular_class_id}
                    onChange={handleAffiliationChange}
                    placeholder=""
                    disabled={disableForm}
                  >
                    {generateOptions(affiliationOptionList())}
                  </MyBsSelect>
                </div>
                <div className="col-md-3">
                  <div className="d-flex flex-wrap">
                    <div className="col-3 text-end">回数:</div>
                    <div className="col-3 px-2">
                      <MyBsSelect
                        label=""
                        name="times"
                        value={formData.times}
                        onChange={handleTimesChange}
                        placeholder=""
                        disabled={disableNonAffiliationWidgets}
                      >
                        {generateOptions(timesOptionList())}
                      </MyBsSelect>
                    </div>
                    <div className="col-3 text-end">級:</div>
                    <div className="col-3 px-2 class-rank-input">
                      <MyBsInput
                        label=""
                        type="number"
                        name="class"
                        onChange={handleClassChange}
                        value={formData.class}
                        placeholder=""
                        readOnly={false}
                        disabled={disableRankInputs}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-1 px-2">
                  <MyBsCheckbox
                    label="特習"
                    name="specialStudy"
                    checked={formData.specialStudy}
                    onChange={handleSpecialCheckboxChange}
                    readOnly={false}
                    disabled={disableRankInputs}
                  />
                </div>
                <div className="col-md-1 px-2">
                  <MyBsCheckbox
                    label="休校"
                    name="closed_flag"
                    checked={formData.closed_flag}
                    onChange={handleKyuukouChange}
                    readOnly={false}
                    disabled={disableNonAffiliationWidgets}
                  />
                </div>
                <div className="col-md-2 text-end">授業料:</div>
                <div className="col-md-1 px-2 class-fees-input">
                  <TextLabel label={getClassFees()} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <Table striped bordered hover responsive>
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                {/* pos is not to be confused with index, it starts from 1 */}
                {renderRow("曜日", pos => {
                  let disabled =
                    disableNonAffiliationWidgets || formData.times < pos;

                  const key = `day_week_${pos}`;

                  return (
                    <MyBsSelect
                      label=""
                      key={key}
                      name={key}
                      value={formData[key] || "no-value"}
                      onChange={handleWeekdayChange}
                      placeholder=""
                      disabled={disabled}
                      readOnly={false}
                    >
                      {generateOptions(daysOptionList())}
                    </MyBsSelect>
                  );
                })}

                {renderRow("時間", pos => {
                  let disabled =
                    disableNonAffiliationWidgets || formData.times < pos;

                  const key = `class_level_${pos}`;

                  return (
                    <MyBsSelect
                      label=""
                      key={key}
                      name={key}
                      value={formData[key]}
                      onChange={handleTimeChange}
                      placeholder=""
                      disabled={disabled}
                      readOnly={false}
                    >
                      {generateOptions(dayTimeOptionList(pos))}
                    </MyBsSelect>
                  );
                })}
                {renderRow("送迎バス", pos => {
                  let disabled =
                    disableNonAffiliationWidgets || formData.times < pos;

                  const key = `bus_flag_${pos}`;

                  return (
                    <div className="d-flex justify-content-center">
                      <MyBsCheckbox
                        label=""
                        key={key}
                        name={key}
                        checked={formData[key]}
                        onChange={handleBusChange}
                        readOnly={false}
                        disabled={disabled}
                      />
                    </div>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="col-md-12 student-class-form-buttons">
            <div className="d-flex justify-content-end">
              <Button
                className="ml-10 btn-danger"
                disabled={disableNonAffiliationWidgets}
                onClick={handleDelete}
              >
                削除
              </Button>
              <Button
                className="ml-10"
                disabled={disableNonAffiliationWidgets}
                onClick={handleSave}
              >
                保存
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentClassForm;
