import { Formik } from "formik";
import MyInput from "src/components/common/FormElements/MyInput";

const TextLabel = ({ label }) => {
  return (
    <Formik initialValues={{ label }} enableReinitialize={true}>
      {formik => (
        <MyInput
          className="input-text-label"
          label=""
          name="label"
          type="text"
          placeholder=""
          disabled
        />
      )}
    </Formik>
  );
};

export default TextLabel;
