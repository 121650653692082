import { useField } from "formik";
import { Form } from "react-bootstrap";
import MyInput from "src/components/common/FormElements/MyInput";
import numberWithComma from "src/utils/numberWithComma";

const BillingFormNumberInput = ({ selectTextOnFocus, ...props }) => {
  const { readOnly, label } = props;
  const [field] = useField(props);

  const className = "billing-form-number-input";

  if (!readOnly) {
    return (
      <MyInput className={className} {...{ selectTextOnFocus, ...props }} />
    );
  }

  const labelClass = !!label ? "" : "d-none";
  const feedbackClass = "feeback px-1";

  const allProps = {
    ...props,
    className,
    value: numberWithComma(field.value),
    type: "string",
    onChange: () => {}, // dummy handler
  };

  return (
    <Form.Group className="my-input" controlId={props.name}>
      <Form.Label className={labelClass}>{label}</Form.Label>
      <Form.Control {...allProps} />
      <Form.Control.Feedback
        className={feedbackClass}
        type="invalid"
      ></Form.Control.Feedback>
    </Form.Group>
  );
};

export default BillingFormNumberInput;
