function numberWithComma(number) {
  const type = typeof number;
  const allowedTypes = ["string", "number"];

  if (number && allowedTypes.includes(type) && !isNaN(Number(number))) {
    return Number(number).toLocaleString("en");
  }

  return number;
}

export default numberWithComma;
