import swal from "sweetalert";

export const swalError = async message => {
  return await swal(message, "", "error");
};

export const swalWarning = async message => {
  return await swal(message, "", "warning");
};

export const swalSuccess = async message => {
  return await swal(message, "", "success");
};

export const swalConfirm = async message => {
  return await swal({
    title: message,
    text: "",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });
};
