function castZero(value) {
  const type = typeof value;

  if (type === "number" || type === "string") {
    let result = Number(value);

    if (!Number.isNaN(result)) {
      return result;
    }
  }

  return 0;
}

export default castZero;
