import React, { useEffect, useState } from "react";
import "../../assets/common/css/dashboarButton.css";
import { Button, Modal, Spinner } from "react-bootstrap";
import privateApi from "../../services/privateApi";
import swal from "sweetalert";
import Calendar from "react-calendar";

import "react-calendar/dist/Calendar.css";
import "./monthlyUpdate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import ReactDatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";
import moment from "moment";
import { getCurrentMonth } from "../../services/auth/token";

const MonthlyUpdate = () => {
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const [showMonthlyUpdateModal, setShowMonthlyUpdateModal] = useState(false);
  const [showAttendanceGenerateModal, setShowAttendanceGenerateModal] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(getCurrentMonth());
  const [selectedDatesToAdd, setSelectedDatesToAdd] = useState([]);
  const [reasonInput, setReasonInput] = useState("");

  const [selectedDates, setSelectedDates] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [reasonInputs, setReasonInputs] = useState({});
  const [currentMonth, setCurrentMonth] = useState("");
  const [loading, setLoading] = useState(false);
  const [startFrom, setStartFrom] = useState(null);
  const [endTo, setEndTo] = useState(null);
  const [step2, setStep2] = useState(false);

  const formatDate = date => {
    return date.toLocaleDateString("ja", { month: "numeric" });
  };

  // Ensure startFrom is always less than endTo
  useEffect(() => {
    if (startFrom >= endTo) {
      setEndTo(new Date(startFrom?.getTime() + 24 * 60 * 60 * 1000)); // Add one day to startFrom
    }
  }, [startFrom, endTo]);

  const currentDate = getCurrentMonth();
  const nextMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  const clearStates = () => {
    setSelectedDatesToAdd([]);
    setSelectedDate(getCurrentMonth());
    setReasonInput("");
    setCurrentMonth("");
  };

  const refactorMonth = () => {
    let date = getCurrentMonth();
    let endMonth = getCurrentMonth();
    let month = getCurrentMonth().getMonth();
    date.setMonth(month);
    endMonth.setMonth(month + 1);

    setStartFrom(new Date(date));
    setEndTo(new Date(endMonth));
  };

  const handleAddDate = () => {
    const selectedDateCopy = new Date(selectedDate);
    selectedDateCopy.setDate(selectedDateCopy.getDate() + 1);

    const selectedDateStr = selectedDateCopy.toISOString().split("T")[0];

    // Check if the selected date is already a holiday or already added
    const isHoliday = holidays.some(event => event.date === selectedDateStr);
    const isAlreadyAdded = selectedDatesToAdd.includes(selectedDateStr);

    if (!isHoliday && !isAlreadyAdded) {
      setSelectedDatesToAdd([...selectedDatesToAdd, selectedDateStr]);
      setSelectedDate(getCurrentMonth()); // Reset selectedDate
    } else if (isAlreadyAdded) {
      swal("この日はすでに追加されています。", "", "warning");
    } else {
      swal("この日はすでに休日です。", "", "error");
    }
  };

  const handleDeleteDate = dateToDelete => {
    const updatedSelectedDates = selectedDatesToAdd.filter(
      date => date !== dateToDelete
    );
    setSelectedDatesToAdd(updatedSelectedDates);
    const updatedReasonInputs = { ...reasonInputs };
    delete updatedReasonInputs[dateToDelete];
    setReasonInputs(updatedReasonInputs);
  };

  const handleReasonChange = (event, date) => {
    const updatedReasonInputs = { ...reasonInputs, [date]: event.target.value };
    setReasonInputs(updatedReasonInputs);
  };

  const handleDeleteHoliday = async dateIdToDelete => {
    // Delete holiday via API and update state
    const response = await privateApi.delete(
      `admin/holidays/${dateIdToDelete}`
    );
    if (response.status === 200) {
      const updatedHolidays = holidays.filter(
        event => event.id !== dateIdToDelete
      );
      setHolidays(updatedHolidays);
      swal("成功しました！", "休日が削除されました！", "success");
    }
  };

  const handleUpdateHoliday = async (dateIdToUpdate, dateToUpdate) => {
    const updateData = {
      name: reasonInputs[dateToUpdate],
      date: dateToUpdate,
    };

    // Update holiday via API and update state
    const response = await privateApi.update(
      `admin/holidays/${dateIdToUpdate}`,
      updateData
    );
    if (response.status === 200) {
      const updatedHolidays = holidays.map(event =>
        event.date === dateToUpdate
          ? { ...event, name: reasonInputs[dateToUpdate] }
          : event
      );
      setHolidays(updatedHolidays);
      swal("成功しました！", "休日が更新されました！", "success");
    }
  };

  const renderAddedDates = () => {
    return selectedDatesToAdd.map(date => (
      <tr key={date}>
        <td>{date}</td>
        <td>
          <input
            type="text"
            value={reasonInputs[date] || ""}
            onChange={e => handleReasonChange(e, date)}
          />
        </td>
        <td className="text-center">
          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={() => handleDeleteDate(date)}
          >
            <FontAwesomeIcon icon="eraser" />
          </button>
        </td>
      </tr>
    ));
  };

  const renderTableRows = () => {
    return holidays.map(holiday => (
      <tr key={holiday.date}>
        <td>{holiday.date}</td>
        <td className="hide-input">
          <input
            type="text"
            value={reasonInputs[holiday.date] || holiday.name}
            onChange={e => handleReasonChange(e, holiday.date)}
          />
        </td>
        <td className="text-center">
          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={() => handleDeleteHoliday(holiday.id)}
          >
            <FontAwesomeIcon icon="trash" />
          </button>
          <button
            className="btn btn-sm btn-success mx-1"
            onClick={() => handleUpdateHoliday(holiday.id, holiday.date)}
          >
            <FontAwesomeIcon icon="check" />
          </button>
        </td>
      </tr>
    ));
  };

  const getHolidays = async month => {
    const response = await privateApi.get(`admin/holidays?date=${month}`);
    if (response.status === 200) {
      setHolidays(response.data.data);
    }
  };

  const tileContent = ({ date }) => {
    const dateStr = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

    const isHoliday = holidays.some(event => event.date === dateStr);
    const isSelected = selectedDates.includes(dateStr);

    if (isHoliday) {
      return (
        <>
          <div className="highlighted-date holiday"></div>{" "}
          <FontAwesomeIcon icon={faTimes} className="cross-icon" />
        </>
      );
    } else if (isSelected) {
      return <div className="highlighted-date selected"></div>;
    }

    return null;
  };

  const handleUpdateProcess = async () => {
    const data = {
      start_from: startFrom && moment(startFrom).format("YYYY-MM"),
      end_to: endTo && moment(endTo).format("YYYY-MM"),
    };
    setLoading(true);
    const response = await privateApi.post(
      `/admin/generate-members-class-sql`,
      data
    );
    if (response.status === 200) {
      await privateApi.post(`/admin/generateAttendance`, {});
      setShowMonthlyUpdateModal(false);
      setLoading(false);

      swal(
        "成功しました！",
        "毎月のプロセスが更新されました！",
        "success"
      ).then(() => {
        window.location.reload();
      });
    } else {
      swal(response.data.message, "", "error");
      setShowMonthlyUpdateModal(false);
      setLoading(false);
    }
  };

  const handleAttendaceGenerate = async () => {
    try {
      setLoading(true);
      const response = await privateApi.post(`/admin/generateAttendance`, {});
      if (response.status === 200) {
        swal("成功しました！", "毎月の出席者数が発生しました!", "success");
        setShowAttendanceGenerateModal(false);
      } else {
        swal(response.data.message, "", "error");
        setShowAttendanceGenerateModal(false);
      }
    } catch (error) {
      console.error("Error occurred:", error);
      swal("エラーが発生しました！", "もう一度お試しください。", "error");
    } finally {
      setLoading(false);
    }
  };

  const addHoliday = async () => {
    const holidayData = selectedDatesToAdd.map(date => ({
      name: reasonInputs[date] || "", // Use reasonInputs for the name
      date: date,
    }));

    const response = await privateApi.post("admin/holidays", {
      holidays: holidayData,
    });
    if (response.status === 200) {
      swal("成功しました！", "休日追加！", "success");
      getHolidays(currentMonth);
      clearStates();
      // setShowHolidayModal(false);
    } else {
      clearStates();
      swal(response.data.message, "", "error");
      setShowHolidayModal(false);
    }
  };

  const handleSetHoliday = async () => {
    addHoliday();
  };

  useEffect(() => {
    refactorMonth();
    getHolidays(currentMonth);
  }, [currentMonth]);

  return (
    <>
      <div className="p-3">
        <p className="headline">月次更新</p>

        <div className="admin-dash btn-container col-md-5">
          <div>
            <button
              onClick={() => setShowHolidayModal(true)}
              className="dash-btn"
            >
              休館日登録
            </button>
          </div>
          <div>
            <button
              onClick={() => setShowMonthlyUpdateModal(true)}
              className="dash-btn"
            >
              月次更新処理
            </button>
          </div>
          <div>
            <button
              onClick={() => setShowAttendanceGenerateModal(true)}
              className="dash-btn"
            >
              出席を生成する
            </button>
          </div>
        </div>
      </div>
      {/* Modal for Monthly update process submission */}
      <Modal
        show={showMonthlyUpdateModal}
        onHide={() => setShowMonthlyUpdateModal(false)}
        // size="xl"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            月次更新 プロセス
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mxy-auto">
          {step2 ? (
            <>
              {startFrom && endTo && (
                <h5>
                  <strong className="text-danger">
                    {formatDate(startFrom)}
                  </strong>
                  &nbsp; のデータが &nbsp; &nbsp;
                  <strong className="text-danger">{formatDate(endTo)}</strong>
                  &nbsp; にコピーされている
                </h5>
              )}

              <p>本当によろしいですか？</p>
            </>
          ) : (
            <>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label>から:</label>
                  <div>
                    <ReactDatePicker
                      className="form-control"
                      selected={startFrom}
                      // onChange={date => {
                      //   if (date) {
                      //     setStartFrom(date);
                      //   }
                      // }}
                      dateFormat="yyyy/MM"
                      showMonthYearPicker
                      locale={ja}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label>への:</label>
                  <div>
                    <ReactDatePicker
                      className="form-control"
                      selected={endTo}
                      // onChange={date => {
                      //   if (date) {
                      //     setEndTo(date);
                      //   }
                      // }}
                      dateFormat="yyyy/MM"
                      showMonthYearPicker
                      locale={ja}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <h5 className="mt-2 mb-0 text-center">月次更新処理を行う</h5>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          {step2 ? (
            <>
              <Button variant="danger" onClick={() => setStep2(false)}>
                前の
              </Button>
              <Button
                variant="success"
                onClick={() => handleUpdateProcess()}
                disabled={loading}
              >
                {loading && (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                )}
                {loading ? "お待ちください..." : "確認"}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="danger"
                onClick={() => setShowMonthlyUpdateModal(false)}
              >
                キャンセル
              </Button>
              <Button variant="success" onClick={() => setStep2(true)}>
                確認
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {/* Modal for Attendance Generate */}
      <Modal
        show={showAttendanceGenerateModal}
        onHide={() => setShowAttendanceGenerateModal(false)}
        // size="xl"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            月次出席率の生成
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>出席を生成する</h4>
          <p>本当によろしいですか？</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => handleAttendaceGenerate()}
            disabled={loading}
          >
            {loading && (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </>
            )}
            {loading ? "お待ちください..." : "確認"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal for holiday update */}
      <Modal
        show={showHolidayModal}
        onHide={() => setShowHolidayModal(false)}
        // size="xl"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            休館日登録画面
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="monthly-update-container">
            <div className="calendar-section">
              <Calendar
                // minDate={new Date(getCurrentMonth().getTime() + 1 * 24 * 60 * 1000)}
                onChange={setSelectedDate}
                value={selectedDate}
                defaultActiveStartDate={nextMonth}
                // onClickMonth={(value) =>
                //   setCurrentMonth(value.toISOString().split("T")[0].slice(0, -3))
                // }
                // selectRange={true} //
                // onViewChange={handleViewChange}
                // onViewChange={({ action, activeStartDate, value, view }) =>
                //   alert("New view is: ", view)
                // }
                tileContent={tileContent}
              />
              <div className="text-center">
                <button
                  className="btn btn-primary mt-2"
                  onClick={handleAddDate}
                >
                  <FontAwesomeIcon icon="plus" />
                </button>
              </div>
            </div>
            <div className="content-section">
              <div className="mt-3">
                {selectedDatesToAdd.length > 0 && (
                  <table className="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>日付</th>
                        <th>休館理由（空白可）</th>
                        <th>アクション</th>
                      </tr>
                    </thead>
                    <tbody>{renderAddedDates()}</tbody>
                  </table>
                )}

                {selectedDatesToAdd.length > 0 && (
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      className="mr-4"
                      variant="danger"
                      onClick={() => setShowHolidayModal(false)}
                    >
                      キャンセル
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => handleSetHoliday()}
                    >
                      確認
                    </Button>
                  </div>
                )}
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>登録済</th>
                      <th>休館理由（空白可）</th>
                      <th>アクション</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableRows()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="danger" onClick={() => setShowHolidayModal(false)}>
            キャンセル
          </Button>
          <Button variant="success" onClick={() => handleSetHoliday()}>
            確認
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default MonthlyUpdate;
