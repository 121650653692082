import { Table as AntTable, ConfigProvider } from "antd";
import "react-resizable/css/styles.css";
import antdTheme from "src/themes/antdTheme";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import subtractMonths from "src/utils/subtractMonths";
import { dateFormat } from "src/utils/formatdate";
import MyInput from "src/components/common/FormElements/MyInput";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { fetchMemberClassesData } from "src/services/privateApi";
import { swalError } from "src/utils/swalHelpers";
import messages from "src/utils/messages";
import StudentClassTableCSVDownload from "./StudentClassTableCSVDownload";
import studentClassTableColumns from "./studentClassTableColumns";
import useStudentClass from "src/hooks/useStudentClass";
import generateClassFormListInfo from "src/utils/generateClassFormListInfo";

// note praveen add date range validation in the future
const validationSchema = Yup.object({
  start_date: Yup.date().nullable(),
  end_date: Yup.date().nullable(),
});

const StudentClassTable = () => {
  const {
    selectedClassId,
    updateState,
    memberData,
    tableFilterButtonRef,
    currentMonth,
    classList,
    fetchMemberClassesDataWrapper,
    getStartDate,
    formikRef,
  } = useStudentClass();

  const startDate = getStartDate(currentMonth);

  const handleRowClick = record => {
    const newClassData = classList.filter(
      val => val.processed_date === record.processed_date
    );
    const newFormListInfo = generateClassFormListInfo(newClassData, record.id);
    updateState(newFormListInfo);
  };

  useEffect(() => {
    if (startDate && memberData.id) {
      fetchMemberClassesDataWrapper(memberData.id, "effect");
    }
  }, [memberData.id, startDate, fetchMemberClassesDataWrapper]);

  return (
    <div className="card mt-3">
      <div className="row m-0 card-header">
        <Formik
          initialValues={{
            start_date: startDate,
            end_date: null,
          }}
          innerRef={formikRef}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={async () => {
            const { setSubmitting } = formikRef.current;
            setSubmitting(true);
            await fetchMemberClassesDataWrapper(memberData.id, "handler");
            setSubmitting(false);
          }}
        >
          {formik => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <div className="col-md-8">
                  <div className="row m-0 align-items-center">
                    <div
                      className="col"
                      style={{ width: "12%", flex: "0 0 auto" }}
                    >
                      処理年月:{" "}
                    </div>
                    <div className="col">
                      <MyInput
                        label=""
                        name="start_date"
                        type="month"
                        placeholder=""
                      ></MyInput>
                    </div>
                    <div
                      className="col"
                      style={{ width: "5%", flex: "0 0 auto" }}
                    >
                      <div className="text-center py-2">~</div>
                    </div>
                    <div className="col">
                      <MyInput
                        label=""
                        name="end_date"
                        type="month"
                        placeholder=""
                      ></MyInput>
                    </div>
                    <div
                      className="col"
                      style={{ width: "14%", flex: "0 0 auto" }}
                    >
                      <button
                        style={{ width: "100%" }}
                        className="btn btn-primary"
                        type="submit"
                        disabled={formik.isSubmitting}
                        ref={tableFilterButtonRef}
                      >
                        絞り込み
                      </button>
                    </div>
                    <div className="col">
                      <StudentClassTableCSVDownload
                        {...{ memberData, classList }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className="table-responsive p-3">
        <ConfigProvider theme={antdTheme}>
          <AntTable
            bordered
            dataSource={classList}
            columns={studentClassTableColumns()}
            rowClassName={({ id }) =>
              selectedClassId === id ? "table-selected-row" : ""
            }
            onRow={(record, rowIndex) => ({
              onClick: e => {
                handleRowClick(record);
              },
            })}
            className="text-left"
            pagination={false}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default StudentClassTable;
