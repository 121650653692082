import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "../../assets/coach/css/styles.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "../../assets/coach/js/scripts";
import routes from "../../routes/coachRoutes";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import NavProvider from "src/provider/navProvider";

const MasterLayout = () => {
  const sidebarToggle = () => {
    document.body.classList.remove("sb-sidenav-toggled");
  };

  return (
    <NavProvider>
      <div className="sb-nav-fixed">
        <Navbar />
        <div id="layoutSidenav">
          <div id="layoutSidenav_nav">
            <Sidebar />
          </div>

          <div id="layoutSidenav_content">
            <main className="p-3">
              <Switch>
                {routes.map((route, idx) => {
                  return (
                    route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      />
                    )
                  );
                })}
                <Redirect from="/coach" to="/coach/dashboard" />
              </Switch>
            </main>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </NavProvider>
  );
};

export default MasterLayout;
