import Nvh from "src/components/common/Nvh";

const bgActiveStyle = {
  backgroundColor: "#d4dfee",
};

const ClassDetails = ({
  monthLabel,
  isSelected,
  date,
  classInfo,
  updatedClass,
  setUpdatedClass,
}) => {
  const getRank = () => {
    const { processed_date: updatedDate, class: updatedRank } = updatedClass;
    const { processed_date: thisDate, class: thisRank } = classInfo;
    return updatedDate === thisDate ? updatedRank : thisRank || "";
  };

  const dayTimeValue = () =>
    [1, 2, 3, 4, 5]
      .map(i => {
        const dayWeek = classInfo?.[`day_week_${i}`];
        const startTime = classInfo?.[`start_time_${i}`];
        const endTime = classInfo?.[`end_time_${i}`];

        if (dayWeek) {
          const day = Nvh({ value: dayWeek });
          const start = Nvh({ value: startTime });
          const end = Nvh({ value: endTime });
          return `${day}(${start}-${end})`;
        }

        return "";
      })
      .filter(Boolean)
      .join(" ");

  return (
    <div
      className={`${isSelected ? "row m-2 shadow rounded p-2" : "row m-2 p-2"}`}
      style={isSelected ? bgActiveStyle : {}}
    >
      <div className="col-md-2 d-flex align-items-start flex-column">
        <div>{monthLabel}:</div>
        <div>{date}</div>
      </div>
      <div className="col-md-10">
        <div style={{ maxWidth: "120px" }} className="current-rank">
          <input
            className="w-50 text-center"
            type="text"
            disabled
            value={classInfo?.regular_class?.affiliation_name || ""}
          />
        </div>
        <div className="right-bottom-container col-md-12 mt-1 border p-1">
          <div className="d-flex justify-content-start">
            <div className="col-md-6 p-2">
              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  所属:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    name="affiliation"
                    value={classInfo?.regular_class?.affiliation_name || ""}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group mb-2 row">
                <label className="col-sm-2 col-form-label text-sm-end">
                  曜日時間:
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    name="day_time"
                    value={dayTimeValue()}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 p-2 pb-0">
              <span>回数：</span>
              <input
                type="text"
                className="w-25 mr-5"
                value={classInfo?.times || ""}
                disabled
              />
              <span>級：</span>
              <input
                type="text"
                className="w-25"
                onChange={e =>
                  setUpdatedClass({ ...classInfo, class: e.target.value })
                }
                value={getRank()}
                disabled={!isSelected}
              />
              <span>（50：特習）</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassDetails;
