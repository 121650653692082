import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { Button, Table } from "react-bootstrap";
import privateApi from "../../../services/privateApi";
import swal from "sweetalert";
import moment from "moment/moment";
import ja from "date-fns/locale/ja";
import Nvh from "../../common/Nvh";
import DateForm from "../../common/DateForm";
import ReactSelect from "react-select";
import { Table as AntTable, Checkbox } from "antd";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { ConfigProvider } from "antd";
import { getCurrentMonth } from "../../../services/auth/token";
import "./sticky.css";
const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8", // Adjust the value to your desired background color
    },
  },
};

const Option2 = ({
  studentInfo,
  setDeposite,
  readOnlyValue,
  trigger,
  setTrigger,
  setKey,
  children,
}) => {
  const [startDate, setStartDate] = useState(getCurrentMonth());
  const [selectedClass, setSelectedClass] = useState({
    id: "",
    class_name: "クラスの選択",
  });

  const [newClassInput, setNewClassInput] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [classTab, setClassTab] = useState([]);
  const [selectedClassTab, setSelectedClassTab] = useState("");
  const [classes, setClasses] = useState([]);
  const [student, setStudent] = useState();
  const [classNumber, setClassNumber] = useState(0);
  const [totalFee, setTotalFee] = useState("--");
  const [singleFee, setSingleFee] = useState("--");
  const [classTimes, setClassTimes] = useState([]);
  const [timeRequired, setTimeRequired] = useState(false);
  const [classLevel, setClassLevel] = useState("");
  const [specialStudy, setSpecialStudy] = useState(false);
  const [closedSchool, setClosedSchool] = useState(false);
  const [classData, setClassData] = useState([]);
  const [startFrom, setStartFrom] = useState();
  const [endTo, setEndTo] = useState();
  const [readOnly, setReadOnly] = useState(true);
  const [warn, setWarn] = useState(false);
  const [deleteClass, setDeleteClass] = useState(false);
  const [defaultClassData, setDefaultClassData] = useState({});
  const [defaltValue, setDefaltValue] = useState(false);
  const [nameSlug, setNameSlug] = useState("");

  const weekDays = ["月", "火", "水", "木", "金", "土", "日"];
  const year = startDate.getFullYear();
  const month = startDate.getMonth() + 1;

  const getClasses = async e => {
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const getAssignedClasses = async (id, date) => {
    const formData = {
      member_id: id,
      processed_date: date,
    };

    const response = await privateApi.post(
      "admin/get-member-classes",
      formData
    );
    if (response.status === 200) {
      const originalArray = response.data.data.classes;

      if (originalArray.length < 1) {
        getAssignedClasses3(id, defaultClassData.processed_date);
        setDefaltValue(true);
      }

      const modifiedArray = originalArray.slice(0, 3);
      setClassTab(modifiedArray);
      setTotalFee(response.data.data.total_amount);

      setSelectedClassTab(modifiedArray[0].regular_class_id);
      getAssignedClassInfo(
        studentInfo.id,
        moment(date).format("YYYY-MM"),
        modifiedArray[0].regular_class_id,
        modifiedArray[0].class_name
      );
    } else {
      console.log("Server Error");
    }
  };

  const getAssignedClasses3 = async (id, date) => {
    const formData = {
      member_id: id,
      processed_date: date,
    };

    const response = await privateApi.post(
      "admin/get-member-classes",
      formData
    );
    if (response.status === 200) {
      const originalArray = response.data.data.classes;

      const modifiedArray = originalArray.slice(0, 3);
      setClassTab(modifiedArray);
      setTotalFee(response.data.data.total_amount);

      setSelectedClassTab(modifiedArray[0].regular_class_id);
      getAssignedClassInfo(
        studentInfo.id,
        moment(date).format("YYYY-MM"),
        modifiedArray[0].regular_class_id,
        modifiedArray[0].class_name
      );
    } else {
      console.log("Server Error");
    }
  };

  const getAssignedClasses2 = async (id, date) => {
    const formData = {
      member_id: id,
      processed_date: date,
    };

    const response = await privateApi.post(
      "admin/get-member-classes",
      formData
    );
    if (response.status === 200) {
      const originalArray = response.data.data.classes;

      const modifiedArray = originalArray.slice(0, 3);
      setClassTab(modifiedArray);
      setTotalFee(response.data.data.total_amount);
    } else {
      console.log("Server Error");
    }
  };

  const getMemberClassesData = async id => {
    const response = await privateApi.get(
      `admin/get-member-classes-data/${id}`
    );
    if (response.status === 200) {
      const classData = response.data.data;
      setClassData(classData);

      if (Array.isArray(classData) && classData.length > 0) {
        const lastChild = classData[classData.length - 1];
        setDefaultClassData(lastChild);
      } else {
        console.log("Array is empty or not an array");
      }
    } else {
      console.log("Server Error");
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("school_slug");
    if (userData) {
      setNameSlug(userData);
    }
  }, []);

  const getDataSource = () => {
    // if (!startFrom && !endTo && nameSlug === 'abiko') {
    //   return classData.slice(0, 6); // Limit to maximum 6 rows
    // }
    return classData;
  };

  useEffect(() => {
    getClasses();
    setStudent(studentInfo);
    getAssignedClasses(studentInfo.id, moment(startDate).format("YYYY-MM"));
    getMemberClassesData(studentInfo.id);
    setReadOnly(readOnlyValue);
  }, [studentInfo, readOnlyValue]);

  useEffect(() => {
    filterHandler();
  }, [startFrom, endTo]);

  const classNumberHandler = async value => {
    if (selectedClass.id === "") {
      swal("警告！", "任意のクラスを選択してください", "warning");
    } else {
      setClassNumber(value);

      const data = {
        regular_class_id: selectedClass.id,
        fee_type: student.charge_type_code,
        times: value,
      };

      classFeeUpdateHandler(data);
    }
  };

  const classFeeUpdateHandler = async data => {
    const response = await privateApi.post("admin/tution-fees-by-class", data);

    if (response.status === 200) {
      setSingleFee(parseInt(response.data.data));
    } else {
      console.log("Server Error");
    }
  };

  const classChangeHandler = async e => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    setSelectedClass({
      id: selectedOption.value,
      class_name: selectedOption.text,
    });

    setBlankHandler();

    getClassTimes(selectedOption.value);
  };

  const getClassTimes = async value => {
    setSingleFee();
    const response = await privateApi.get(`admin/class-times/${value}`);

    if (response.status === 200) {
      let classTimes = response.data.data.times;
      let time_required = response.data.data.day_selection_flag;
      classTimes.unshift(0); // Add 0 to the beginning of the array
      setClassTimes(classTimes);
      setTimeRequired(time_required === 1 ? true : false);
    } else {
      console.log("Server Error");
    }
  };

  const [selectedWeekdays, setSelectedWeekdays] = useState(Array(5).fill(""));
  const [selectedTimes, setSelectedTimes] = useState(Array(5).fill(""));
  const [selectedBuses, setSelectedBuses] = useState(Array(5).fill(false));
  const [timeOptionsByDay, setTimeOptionsByDay] = useState(
    Array.from({ length: 5 }, () => [])
  );

  const setBlankHandler = () => {
    setTimeOptionsByDay(Array.from({ length: 5 }, () => []));
    setSelectedWeekdays(Array(5).fill(""));
    setSelectedTimes(Array(5).fill(""));
    setSelectedBuses(Array(5).fill(""));
    setClassNumber(0);
    setClassTimes([]);
    setClassLevel("");
    setSpecialStudy(false);
    setClosedSchool(false);
    setSingleFee(0);
  };

  const handleWeekdayChange = (event, index) => {
    setWarn(false);
    if (selectedClass.id === "") {
      swal("警告！", "任意のクラスを選択してください", "warning");
    } else {
      const { value } = event.target;
      setSelectedWeekdays(prevWeekdays => {
        const updatedWeekdays = [...prevWeekdays];
        updatedWeekdays[index] = value;
        return updatedWeekdays;
      });
      classDayTimeHandler(event.target.value, index);
    }
  };

  const handleTimeChange = (event, index) => {
    const { value } = event.target;

    console.log("handle time change before", value);

    setSelectedTimes(prevTimes => {
      const updatedTimes = [...prevTimes];
      const [startTime, endTime, level] = value.split("-"); // Split the value into start_time and end_time
      updatedTimes[index] = {
        start_time: startTime,
        end_time: endTime,
        class_level: level,
      };
      return updatedTimes;
    });
  };

  const handleBusChange = (event, index) => {
    const { checked } = event.target;
    setSelectedBuses(prevBuses => {
      const updatedBuses = [...prevBuses];
      updatedBuses[index] = checked;
      return updatedBuses;
    });
  };

  const classLevelHander = selectedOption => {
    setClassLevel(selectedOption.value);
    setSpecialStudy(selectedOption.value === "0");
    // setTimeOptionsByDay(Array.from({ length: 5 }, () => []));
  };

  const handleCheckboxChange = () => {
    setSpecialStudy(!specialStudy);

    if (!specialStudy) {
      setClassLevel("0");
    } else {
      setClassLevel("");
    }
  };

  useEffect(() => {
    if (classLevel == "0") {
      setSpecialStudy(true);
    } else {
      setSpecialStudy(false);
    }
  }, [classLevel]);

  const getAssignedClassInfo = async (id, date, class_id, class_name) => {
    setTimeOptionsByDay(Array.from({ length: 5 }, () => []));

    setSelectedClass({
      id: class_id,
      class_name: class_name,
    });

    getClassTimes(class_id);

    const formData = {
      member_id: id,
      processed_date: defaltValue ? defaultClassData.processed_date : date,
      class_id: class_id,
    };

    const response = await privateApi.post(
      "admin/get-member-classes-info",
      formData
    );
    if (response.status === 200) {
      const weekdays = response.data.data.weekdays;
      setSelectedWeekdays(response.data.data.weekdays);
      setSelectedBuses(response.data.data.buses);
      setSelectedTimes(response.data.data.class_times);
      console.log("Class level fetch from API", response.data.data.class_level);
      setClassNumber(response.data.data.times);
      setClassLevel(response.data.data.class_level);
      setClosedSchool(response.data.data.closed_flag);
      setSingleFee(response.data.data.single_billing_amount);

      for (let i = 0; i < weekdays?.length; i++) {
        const weekday = weekdays[i];
        if (weekday !== "") {
          await classDayTimeHandler2(
            weekday,
            i,
            class_id,
            response.data.data.class_times,
            response.data.data.class_level
          );
        }
      }
    } else {
      console.log("Server Error");
    }
  };

  const classDayTimeHandler2 = async (
    weekday,
    index,
    class_id,
    selected_class_time,
    class_level2
  ) => {
    const formData = {
      regular_class_id: class_id,
      day_week: weekday,
      member_class_level: "",
    };

    const response = await privateApi.post(
      "admin/class-schedules-by-week",
      formData
    );

    const class_level = class_level2;

    if (response.status === 200) {
      const dayTimeSlots = response.data.data;

      setTimeOptionsByDay(prevOptions => {
        const newOptions = [...prevOptions];
        newOptions[index] = dayTimeSlots.map((slot, idx) => {
          const { start_time, end_time, id } = slot;
          const optionValue = `${start_time}-${end_time}-${id}`;

          const isSelected = slot.class_level
            ? selected_class_time[index]?.start_time == start_time &&
              (selected_class_time[index]?.class_level == id ||
                (class_level >= slot.class_level?.from_level &&
                  class_level <= slot.class_level?.to_level))
            : selected_class_time[index]?.start_time == start_time;

          return (
            <option key={idx} value={optionValue} selected={isSelected}>
              {start_time} ({slot.class_level?.in_text ?? "---"})
            </option>
          );
        });
        return newOptions;
      });
    } else {
      console.log("Server Error");
    }
  };

  const getDeposite = async (id, date) => {
    const formData = {
      member_id: id,
      date: date,
    };

    const response = await privateApi.post("admin/search-deposite", formData);
    if (response.status === 200) {
      setDeposite(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const deleteModule = async id => {
    swal({
      title: "削除の確認",
      text: "消去してもよろしいですか?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        const data = {
          member_id: id,
          processed_date: moment(startDate).format("YYYY-MM"),
          regular_class_id: selectedClass.id,
        };

        const response = await privateApi.post(
          "/admin/member-class-delete",
          data
        );
        if (response.status === 200) {
          swal("削除しました！", "クラスが削除されました!", "success");
          getMemberClassesData(id);
          getAssignedClasses(
            studentInfo.id,
            moment(startDate).format("YYYY-MM")
          );
          setSingleFee("--");
          setTrigger(!trigger);
          setDeleteClass(false);
        } else {
          swal(response.data.message, "", "error");
        }
      } else {
        swal("キャンセルしました！", "モジュールは削除されていません。");
      }
    });
  };

  const handleFormSubmit = async (stu_id, status) => {
    if (deleteClass) {
      deleteModule(stu_id);
      if (status === 2) {
        setKey("tab3");
      }
    } else {
      const updatedWeekdays = selectedWeekdays.map((weekday, index) =>
        selectedTimes[index] ? weekday : ""
      );

      setSelectedWeekdays(updatedWeekdays);

      if (timeRequired) {
        if (
          classNumber > updatedWeekdays.filter(day => day !== "")?.length ||
          classNumber == 0
        ) {
          swal("クラス日付時刻が必要です", "", "error");
          setWarn(true);
        } else {
          formSubmit(stu_id, status);
        }
      } else {
        if (classNumber == 0) {
          swal("クラス日付時刻が必要です", "", "error");
        } else {
          formSubmit(stu_id, status);
        }
      }
    }
  };

  const formSubmit = async (stu_id, status) => {
    console.log("submit selectedTimes", selectedTimes);

    const timesToInclude = Math.min(classNumber, 5); // Ensure it doesn't exceed 5

    const formData = {
      weekdays: selectedWeekdays
        .slice(0, timesToInclude)
        .map((weekday, index) => (selectedTimes[index] !== "" ? weekday : ""))
        .concat(Array(5 - timesToInclude).fill("")),
      class_times: selectedTimes
        .slice(0, timesToInclude)
        .concat(Array(5 - timesToInclude).fill("")),
      buses: selectedBuses
        .slice(0, timesToInclude)
        .concat(Array(5 - timesToInclude).fill("")),
      member_id: stu_id,
      month: moment(startDate).format("YYYY-MM"),
      class_id: selectedClass.id,
      times: timesToInclude,
      class_level: classLevel,
      billing_amount: singleFee,
      closed_flag: closedSchool,
    };

    const response = await privateApi.post(
      "admin/member-class-assign",
      formData
    );

    if (response.status === 200) {
      swal(response.data.message, "", "success");
      getAssignedClasses2(student.id, moment(startDate).format("YYYY-MM"));
      getMemberClassesData(studentInfo.id);
      getDeposite(stu_id, moment(startDate).format("YYYY-MM"));
      if (status === 2) {
        setKey("tab3");
      }
    } else {
      console.log("Server Error");
    }
  };

  const isWeekdayDisabled = (weekday, index) => {
    return selectedWeekdays.some(
      (selectedWeekday, selectedIndex) =>
        selectedIndex !== index && selectedWeekday === weekday
    );
  };

  const classDayTimeHandler = async (weekday, index) => {
    // alert(classLevel)
    const formData = {
      regular_class_id: selectedClass.id,
      day_week: weekday,
      member_class_level: "",
    };

    const response = await privateApi.post(
      "admin/class-schedules-by-week",
      formData
    );

    if (response.status === 200) {
      const dayTimeSlots = response.data.data;

      if (dayTimeSlots.every(obj => obj.start_time === null)) {
        swal(response.data.message, "", "warning");

        setSelectedTimes(prevTimes => {
          const newTimes = [...prevTimes];
          newTimes[index] = ""; // Reset the selected time for the day
          return newTimes;
        });
        setTimeOptionsByDay(prevOptions => {
          const newOptions = [...prevOptions];
          newOptions[index] = "";
          return newOptions;
        });
      } else {
        setSelectedTimes(prevTimes => {
          const newTimes = [...prevTimes];
          newTimes[index] = ""; // Reset the selected time for the day
          return newTimes;
        });

        const timeOptions = dayTimeSlots.map((slot, index) => {
          const { start_time, end_time, id } = slot;
          return (
            <option key={index} value={`${start_time}-${end_time}-${id}`}>
              {start_time} ({slot.class_level?.in_text ?? "---"})
            </option>
          );
        });

        setTimeOptionsByDay(prevOptions => {
          const newOptions = [...prevOptions];
          newOptions[index] = timeOptions;
          return newOptions;
        });
      }
    } else {
      console.log("Server Error");
    }
  };

  const selectHandler = index => {
    if (selectedRowIndex === index) {
      setSelectedRowIndex("");
    } else {
      setSelectedRowIndex(index);
    }
  };

  let start, end;

  const filterHandler = async () => {
    if (startFrom) start = moment(startFrom).format("YYYY-MM");
    if (endTo) end = moment(endTo).format("YYYY-MM");

    const response = await privateApi.get(
      `admin/get-member-classes-data/${studentInfo.id}?start_date=${
        start ? start : ""
      }&end_date=${end ? end : ""}`
    );
    if (response.status === 200) {
      setClassData(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  // CSV
  const getstudents_class_csv_export = async () => {
    try {
      const response = await privateApi.get(
        `admin/get-member-classes-data/${studentInfo.id}`
      );
      if (response.status === 200) {
        return response.data.data; // Return the data
      } else {
        console.log("Server Error");
        return []; // Return an empty array in case of an error
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      return []; // Return an empty array in case of an error
    }
  };

  async function download_table_as_csv(
    table_id,
    classDataCSVExport,
    separator = ","
  ) {
    var csv = [];
    var header = [];
    header.push('"' + "会員番号" + '"');
    header.push('"' + "氏名" + '"');
    header.push('"' + "処理年月" + '"');
    header.push('"' + "回数" + '"');
    header.push('"' + "所属" + '"');
    header.push('"' + "級" + '"');
    header.push('"' + "休校" + '"');
    header.push('"' + "曜日1" + '"');
    header.push('"' + "時間1" + '"');
    header.push('"' + "バス1" + '"');
    header.push('"' + "曜日2" + '"');
    header.push('"' + "時間2" + '"');
    header.push('"' + "バス2" + '"');
    header.push('"' + "曜日3" + '"');
    header.push('"' + "時間3" + '"');
    header.push('"' + "バス3" + '"');
    header.push('"' + "曜日4" + '"');
    header.push('"' + "時間4" + '"');
    header.push('"' + "バス4" + '"');
    header.push('"' + "曜日5" + '"');
    header.push('"' + "時間5" + '"');
    header.push('"' + "バス5" + '"');

    csv.push(header.join(separator));
    classDataCSVExport &&
      classDataCSVExport.forEach(data => {
        var row = [];
        row.push('"' + Nvh({ value: data.member.member_code }) + '"');
        row.push('"' + Nvh({ value: data.member.name }) + '"');
        row.push('"' + Nvh({ value: data.processed_date }) + '"');
        row.push('"' + Nvh({ value: data.times }) + '"');
        row.push(
          '"' + Nvh({ value: data.regular_class.affiliation_name }) + '"'
        );
        row.push('"' + Nvh({ value: data.class }) + '"');
        row.push('"' + Nvh({ value: data.closed_flag }) + '"');
        row.push('"' + Nvh({ value: data.day_week_1 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_1 }) + '"');
        if (data.bus_flag_1 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_1 }) + '"');
        } else {
          if (data.day_week_1 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_1 }) + '"');
          } else {
            row.push('""');
          }
        }
        row.push('"' + Nvh({ value: data.day_week_2 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_2 }) + '"');
        if (data.bus_flag_2 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_2 }) + '"');
        } else {
          if (data.day_week_2 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_2 }) + '"');
          } else {
            row.push('""');
          }
        }

        row.push('"' + Nvh({ value: data.day_week_3 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_3 }) + '"');
        if (data.bus_flag_3 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_3 }) + '"');
        } else {
          if (data.day_week_3 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_3 }) + '"');
          } else {
            row.push('""');
          }
        }
        row.push('"' + Nvh({ value: data.day_week_4 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_4 }) + '"');
        if (data.bus_flag_4 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_4 }) + '"');
        } else {
          if (data.day_week_4 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_4 }) + '"');
          } else {
            row.push('""');
          }
        }
        row.push('"' + Nvh({ value: data.day_week_5 }) + '"');
        row.push('"' + Nvh({ value: data.start_time_5 }) + '"');
        if (data.bus_flag_5 == "1") {
          row.push('"' + Nvh({ value: data.bus_flag_5 }) + '"');
        } else {
          if (data.day_week_5 != null) {
            row.push('"' + Nvh({ value: data.bus_flag_5 }) + '"');
          } else {
            row.push('""');
          }
        }
        csv.push(row.join(separator));
      });
    var csv_string = csv.join("\n");
    // Download it
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async table_id => {
    try {
      const classDataCSVExport = await getstudents_class_csv_export();
      await download_table_as_csv(table_id, classDataCSVExport); // Pass the data to the download function
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };

  const options = [
    { value: "", label: "--" },
    { value: "0", label: "特習" },
    ...[...Array(25)].map((x, i) => ({
      value: `${i + 1}`,
      label: `${i + 1}`,
    })),
  ];

  const dynamicColumns = [...Array(5)].flatMap((_, i) => [
    {
      title: `曜日${i + 1}`,
      dataIndex: `day_week_${i + 1}`,
      key: `day_week_${i + 1}`,
      width: 150,
      sorter: (a, b) => {
        const aValue = (a[`day_week_${i + 1}`] || "").toString();
        const bValue = (b[`day_week_${i + 1}`] || "").toString();
        return aValue.localeCompare(bValue);
      },
    },
    {
      title: `時間${i + 1}`,
      dataIndex: `start_time_${i + 1}`,
      key: `start_time_${i + 1}`,
      width: 150,
      sorter: (a, b) => {
        const aValue = (a[`start_time_${i + 1}`] || "").toString();
        const bValue = (b[`start_time_${i + 1}`] || "").toString();
        return aValue.localeCompare(bValue);
      },
    },
    {
      title: `バス${i + 1}`,
      dataIndex: `bus_flag_${i + 1}`,
      key: `bus_flag_${i + 1}`,
      width: 150,
      render: text => <Checkbox checked={text === 1} readOnly />,
      sorter: (a, b) => {
        const aValue = Number(a[`bus_flag_${i + 1}`]);
        const bValue = Number(b[`bus_flag_${i + 1}`]);
        return aValue - bValue;
      },
    },
  ]);

  const [columns, setColumns] = useState([
    {
      title: "処理年月",
      width: 150,
      dataIndex: "processed_date",
      key: "processed_date",
      render: (text, record) => DateForm({ value: text, type: "short" }),
      sorter: (a, b) => new Date(a.processed_date) - new Date(b.processed_date),
      defaultSortOrder: nameSlug == "abiko" ? "descend" : "ascend",
    },
    {
      title: "回数",
      width: 150,
      dataIndex: "times",
      key: "times",
      sorter: (a, b) => a.times - b.times,
    },
    {
      title: "所属",
      dataIndex: "affiliation_name",
      key: "affiliation_name",
      width: 150,
      render: (text, record) => (
        <span>{Nvh({ value: record?.regular_class?.affiliation_name })}</span>
      ),
      sorter: (a, b) =>
        a.regular_class?.affiliation_name.localeCompare(
          b.regular_class?.affiliation_name
        ),
    },
    {
      title: "級",
      width: 150,
      dataIndex: "class",
      key: "class",
      sorter: (a, b) => {
        const classA = a.class || "";
        const classB = b.class || "";
        return classA.localeCompare(classB);
      },
    },
    {
      title: "休校",
      width: 150,
      dataIndex: "closed_flag",
      key: "closed_flag",
      render: text => <Checkbox checked={text === 1} readOnly />,
      sorter: (a, b) => a.closed_flag - b.closed_flag,
    },
    // Include the dynamic columns
    ...dynamicColumns,
  ]);

  const handleResize =
    index =>
    (e, { size }) => {
      setColumns(columns => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = record => {
    const key = record.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );
  };

  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  return (
    <>
      <div class="container-fluid">
        <div className="pb-2 sticky-top">
          {children}

          <div className="row  my-2">
            <div className="col-md-5 row">
              <div className="col-3 text-md-end pt-1">年月:</div>
              <div className="col-3">
                <ReactDatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={date => {
                    if (date) {
                      setBlankHandler();
                      setSelectedClassTab();
                      setStartDate(date);
                      setSelectedRowIndex("");
                      getAssignedClasses(
                        student.id,
                        moment(date).format("YYYY-MM")
                      );
                    }
                  }}
                  dateFormat="yyyy/MM"
                  showMonthYearPicker
                  locale={ja}
                />
              </div>
              {!readOnly && (
                <div className="col-md-3">
                  <Button
                    variant="primary"
                    className="btn-block px-4"
                    onClick={() => handleFormSubmit(student?.id, 1)}
                  >
                    追加
                  </Button>
                </div>
              )}
            </div>
            <div className="col-md-7"></div>
          </div>

          <div className="px-4 border py-3">
            <div className="row">
              <div className="col-md-11 col-9">
                <div className="row col-7">
                  <div className="col-md-2 pt-1">処理年月:</div>
                  <div className="col-md-4">
                    <input
                      className="form-control inputs_bg"
                      value={year + "年 " + month + "月"}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-1 col-3">
                <input
                  class="form-control inputs_bg text-center"
                  readonly=""
                  value="請求額"
                ></input>
              </div>
            </div>

            <div className="row  mt-3">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-auto">
                    {classTab?.map((classt, i) => (
                      <span
                        key={i}
                        className={`mb-0 border btn shadow-md ${
                          selectedClassTab === classt.regular_class_id
                            ? "btn-secondary"
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedRowIndex("");
                          setSelectedClassTab(classt.regular_class_id);
                          getAssignedClassInfo(
                            student.id,
                            moment(startDate).format("YYYY-MM"),
                            classt.regular_class_id,
                            classt.class_name
                          );
                          setNewClassInput(false);
                        }}
                      >
                        {classt.class_name}
                      </span>
                    ))}

                    {[...Array(3 - classTab?.length)].map((classt, i) => (
                      <span
                        className={`mb-0 border btn shadow-md ${
                          newClassInput && i === 0 ? "btn-secondary px-5" : ""
                        }`}
                        onClick={() => {
                          setBlankHandler();
                          setNewClassInput(true);
                          setSelectedClassTab();
                          setClassLevel("");
                        }}
                      >
                        ---
                      </span>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-8 rtl">
                    <p className="mb-2">授業料合計</p>
                  </div>
                  <div className="col-2">
                    <input
                      className="form-control inputs_bg text-center"
                      value={totalFee}
                      readOnly
                    />
                  </div>
                  <div className="col-2">
                    <input
                      className="form-control text-center"
                      value={totalFee}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-11 border px-2">
              <div className="row mt-3">
                <div className="col-md-1 text-sm-end">所属:</div>

                <div className="col-md-3">
                  <select
                    className="form-control"
                    value={selectedClass.id}
                    onChange={classChangeHandler}
                    disabled={readOnly}
                  >
                    <option value="">クラスの選択</option>
                    {classes.map((st_class, i) => (
                      <option key={i} value={st_class.id}>
                        {st_class.affiliation_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-1 col-3">
                  <p className="pt-1 text-sm-end">回数:</p>
                </div>
                <div className="col-md-1 col-9">
                  <select
                    className="form-control"
                    value={classNumber}
                    onChange={e => classNumberHandler(e.target.value)}
                    disabled={readOnly}
                  >
                    {classTimes.map((x, i) => (
                      <option key={i} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-1 col-3">
                  <p className="text-sm-end pt-1">級:</p>
                </div>
                <div className="col-md-1 col-9">
                  {readOnly ? (
                    <select className="form-control" disabled={readOnly}>
                      {classLevel ? (
                        <option value="">
                          {classLevel == 0
                            ? "特習"
                            : classLevel == ""
                              ? "--"
                              : classLevel}
                        </option>
                      ) : (
                        <option>--</option>
                      )}
                    </select>
                  ) : (
                    <ReactSelect
                      options={options}
                      placeholder="--"
                      value={options.find(
                        option => option.value === classLevel
                      )}
                      onChange={classLevelHander}
                      menuMaxHeight={200}
                    />
                  )}
                </div>

                <div className="col-md-1 col-6 pt-2">
                  <td className="text-center">
                    {readOnly ? (
                      <input
                        type="checkbox"
                        name="specialStudy"
                        checked={specialStudy}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        name="specialStudy"
                        onChange={handleCheckboxChange}
                        checked={specialStudy}
                      />
                    )}
                    &nbsp; 特習
                  </td>
                </div>
                <div className="col-md-1 col-6 pt-2">
                  <td className="text-center">
                    {readOnly ? (
                      <input
                        type="checkbox"
                        name="closedSchool"
                        checked={closedSchool}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        name="closedSchool"
                        onChange={() => {
                          setClosedSchool(!closedSchool);
                          const data = {
                            regular_class_id: selectedClass.id,
                            fee_type: !closedSchool
                              ? "閉校"
                              : student.charge_type_code,
                            times: String(classNumber),
                          };

                          classFeeUpdateHandler(data);
                        }}
                        checked={closedSchool}
                      />
                    )}
                    &nbsp; 休校
                  </td>
                </div>

                <div className="col-md-1 col-3">
                  <p className="mb-2 pt-2">授業料:</p>
                </div>
                <div className="col-md-1 col-9">
                  <input
                    className="form-control inputs_bg text-center"
                    value={singleFee}
                    readOnly
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-10">
                  <Table className="m-2" striped bordered hover responsive>
                    <thead>
                      <tr></tr>
                    </thead>

                    <tbody>
                      <tr>
                        <th
                          className="text-center"
                          style={{ width: "145px", whiteSpace: "nowrap" }}
                        >
                          曜日
                        </th>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <td key={index}>
                            <select
                              style={{ width: "230px" }}
                              className={`form-control ${
                                index < classNumber &&
                                warn &&
                                timeRequired &&
                                selectedWeekdays[index] == ""
                                  ? "is-invalid2"
                                  : ""
                              }`}
                              name={`weekday-${index}`}
                              value={selectedWeekdays[index]}
                              onChange={event =>
                                handleWeekdayChange(event, index)
                              }
                              disabled={index >= classNumber || readOnly}
                            >
                              <option value="">--</option>
                              {weekDays.map((x, i) => (
                                <option
                                  key={i}
                                  value={x}
                                  disabled={isWeekdayDisabled(x, index)}
                                >
                                  {x}
                                </option>
                              ))}
                            </select>
                          </td>
                        ))}
                      </tr>

                      <tr>
                        <th
                          className="text-center"
                          style={{ width: "145px", whiteSpace: "nowrap" }}
                        >
                          時間
                        </th>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <td key={index}>
                            <select
                              style={{ width: "230px" }}
                              className={`form-control ${
                                index < classNumber &&
                                warn &&
                                timeRequired &&
                                selectedWeekdays[index] == ""
                                  ? "is-invalid2"
                                  : ""
                              }`}
                              name={`time-${index}`}
                              onChange={e => handleTimeChange(e, index)}
                              disabled={index >= classNumber || readOnly}
                            >
                              <option value="">--</option>
                              {timeOptionsByDay[index]}
                            </select>
                          </td>
                        ))}
                      </tr>

                      <tr>
                        <th
                          className="text-center"
                          style={{ width: "145px", whiteSpace: "nowrap" }}
                        >
                          送迎バス
                        </th>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <td key={index} className="text-center">
                            <input
                              type="checkbox"
                              name={`bus-${index}`}
                              onChange={event => handleBusChange(event, index)}
                              disabled={index >= classNumber || readOnly}
                              checked={selectedBuses[index]}
                            />
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <div className="col-md-2 d-flex justify-content-center">
                  <div className="my-auto">
                    <input
                      type="checkbox"
                      name="checkbox"
                      onClick={() => setDeleteClass(!deleteClass)}
                      checked={deleteClass}
                    />{" "}
                    <span>このクラスを削除</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="ml-auto">
                {!readOnly && (
                  <>
                    <Button
                      variant="primary"
                      className="btn-block mt-4 mr-5 px-4"
                      onClick={() => handleFormSubmit(student?.id, 2)}
                    >
                      次へ
                    </Button>
                    <Button
                      variant="primary"
                      className="btn-block mt-4 px-4"
                      onClick={() => handleFormSubmit(student?.id, 1)}
                    >
                      保存
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3 overflow-auto">
          <div className="card-header">
            <div className="row">
              <div className="col-md-6">
                <div className="row align-items-center">
                  <div className="col-md-2">処理年月: </div>

                  <div className="col-md-2">
                    <ReactDatePicker
                      className="form-control"
                      selected={startFrom}
                      onChange={date => {
                        if (date) {
                          setStartFrom(date);
                        } else setStartFrom();
                      }}
                      dateFormat="yyyy/MM"
                      showMonthYearPicker
                      locale={ja}
                    />
                  </div>
                  <div className="col-md-1">
                    <div className="text-center py-2">~</div>
                  </div>
                  <div className="col-md-2">
                    <ReactDatePicker
                      className="form-control"
                      selected={endTo}
                      onChange={date => {
                        if (date) {
                          setEndTo(date);
                        } else {
                          setEndTo();
                        }
                      }}
                      dateFormat="yyyy/MM"
                      showMonthYearPicker
                      locale={ja}
                    />
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-primary w-100"
                      onClick={filterHandler}
                    >
                      絞り込み
                    </button>
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        handleDownloadCSV(
                          "クラス_" +
                            student.member_code +
                            "_" +
                            student.furigana
                        );
                      }}
                    >
                      <i className="fas fa-download fa-fw"></i>
                      CSV
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div>
              <ConfigProvider theme={customTheme}>
                <AntTable
                  bordered
                  dataSource={getDataSource()}
                  columns={resizableColumns}
                  components={components}
                  className="table-striped-rows"
                  pagination={false}
                  scroll={{ x: 1500 }}
                  rowClassName={rowClassName}
                  onRow={(record, index) => ({
                    onClick: () => {
                      handleRowClick(record);
                      setDefaltValue(false);
                      getAssignedClasses2(student.id, record.processed_date);
                      setSelectedClassTab(record.regular_class?.id);
                      getAssignedClassInfo(
                        student.id,
                        record.processed_date,
                        record.regular_class?.id,
                        record.regular_class?.affiliation_name
                      );
                      setNewClassInput(false);
                      selectHandler(index);
                      setStartDate(new Date(record.processed_date));
                    },
                  })}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Option2;
