import moment from "moment";

const StudentDetails = ({ memberData }) => {
  const renderInputField = (label, value) => (
    <div className="rh-input-wrapper d-flex align-items-center">
      <label>{label}</label>
      <input type="text" value={value || ""} disabled />
    </div>
  );

  const memberAge =
    memberData?.birthday && moment().diff(memberData?.birthday, "years");

  return (
    <div className="rh-student-details border">
      <div className="d-flex justify-content-between">
        {renderInputField("氏名:", memberData?.name)}
        {renderInputField("性別:", memberData?.gender)}
        {renderInputField("退校日:", memberData?.leave_date)}
        {renderInputField("料金種別:", memberData?.charge_type_code)}
      </div>
      <div className="d-flex justify-content-between">
        {renderInputField("フリガナ:", memberData?.furigana)}
        {renderInputField("年齢:", memberAge)}
        {renderInputField("入会日:", memberData?.joining_date)}
        {renderInputField("納付区分:", memberData?.payment_type_code)}
      </div>
      <div className="d-flex">
        {renderInputField("備考:", memberData?.note)}
      </div>
    </div>
  );
};

export default StudentDetails;
